import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { selectCount } from "../features/counter/counterSlice";
import { useSelector } from "react-redux";
import logoImage from "../Images/safety-logo_only.png";
import logoImageBlack from "../Images/safety-logo_only_Black.png";
import logoImageWhite from "../Images/full-logo.png";
import userImage from "../Images/userAccount.png";
import userIcon from "../Images/userProfile3.png";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import testImage from "../Images/endless-clouds.svg";
import topPattern from "../Images/pattern_top.png";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";

import firebaseApp from "../firebase";
import { getAuth, signOut } from "firebase/auth";

function Header(props) {
  const [userProfileImage, setUserProfileImage] = useState(null);
  const history = useNavigate();
  const items = useSelector(selectCount);
  const [stateButton, setStateButton] = useState("States");
  const [stateName, setStateName] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [larauser, setLaraUser] = useState(props.passLaraUser);

  useEffect(() => {
    let states = JSON.parse(localStorage.getItem("states"));
    if (states == "" || states == null) {
      setStateName(props.state);
    } else {
      setStateName(states);
    }
  }, [props.state]);

  const directToBasket = () => {
    history("/basket");
  };

  const directToHome = () => {
    if (!props?.passLaraUser) {
      setStateButton("States");
      setSearchKey("");
      props.passState("All");
      props.changeSearchKey("");
      history("/");
    }
    // props.resetCourseKey(props.keyReset + 1);
  };

  const onClickLogin = () => {
    history("/login");
  };

  const onClickSignUp = () => {
    history("/signup");
  };

  const onClickMyCourses = () => {
    history("/mycourses");
  };

  const onClickLogOut = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        props.passUser(null);
        history("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const onClickMyOrders = () => {
    history("/myorders");
  };

  const onClickMyTeam = () => {
    history("/team");
  };

  const onClickMyCompany = () => {
    history("/myteam");
  };

  const onClickState = (state) => {
    setStateButton(state);
    props.passState(state);
    // history("/", { stateName: state });
  };

  const onClickSearch = () => {
    props.changeSearchKey(searchKey);
  };

  const onClickMyCertificates = () => {
    history("/mycertificates");
  };

  const onClickNotifications = () => {
    history("/notifications");
  };

  const onClickLaraLogOut = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        localStorage.removeItem("larauser");
        // props.changeLaraUser(null);
        props.changeReset(props.propReset + 1);

        history("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  // useEffect(() => {
  //   // console.log("Search Key:", searchKey);
  // }, [searchKey]);

  return (
    // <div>
    <Container
    // style={{
    //   gridTemplateColumns: props.userInfo ? "auto 140px" : "auto 205px",
    // }}
    >
      <LogoContainer>
        <Logo src={logoImageWhite} onClick={directToHome} />
        <BetaVersion>BETA</BetaVersion>
      </LogoContainer>

      {/* <SearchContainer>
        <FilterButton>
          <FilterIconContainer>{stateButton}</FilterIconContainer>
          <StateManu className="statemanu">
            <FirstState onClick={() => onClickState("All")}>All</FirstState>
            {stateName ? (
              stateName.map((item) => (
                <FirstState onClick={() => onClickState(item)}>
                  {item}
                </FirstState>
              ))
            ) : (
              <div>State...</div>
            )}
          </StateManu>
        </FilterButton>
        <Search
          type="text"
          value={searchKey}
          placeholder="Search..."
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <SearchButton onClick={() => onClickSearch()}>
          <SearchIconContainer>
            <SearchSVG
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </SearchSVG>
          </SearchIconContainer>
        </SearchButton>
      </SearchContainer> */}
      {!props?.passLaraUser ? (
        <UserContainer>
          {/* <UserName>Bahar</UserName> */}

          {/* <MenuItems>Login</MenuItems> */}
          {props.userInfo ? (
            <Tooltip
              title="Notification"
              style={{ zIndex: 10000000000000000000000000001 }}
            >
              <LogoutLogoContainer onClick={() => onClickNotifications()}>
                {/* <LogoutRoundedIcon
              style={{ fill: "black", height: "20px", width: "20px" }}
            /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-bell"
                  viewBox="0 0 16 16"
                  style={{
                    fill: "black",
                    height: "20px",
                    width: "20px",
                  }}
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                </svg>
                <CartCount>
                  <CartNum>{props.numberOfNotifications}</CartNum>
                </CartCount>
              </LogoutLogoContainer>
            </Tooltip>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
          {props.userInfo ? (
            <Tooltip
              title="Personal Cart"
              style={{ zIndex: 10000000000000000000000000001 }}
            >
              <MenuCart className="cart-container" onClick={directToBasket}>
                <ShoppingCartIcon
                  style={{ fill: "black", height: "20px", width: "20px" }}
                  className="shopping-cart"
                />
                {/* <CartSVG
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-cart3"
            viewBox="0 0 16 16"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </CartSVG> */}
                <CartCount>
                  <CartNum>{items.length}</CartNum>
                </CartCount>
              </MenuCart>
            </Tooltip>
          ) : (
            <div></div>
          )}

          {props.userInfo ? (
            <UserImageContainer>
              {/* Dropdown menu start */}
              <div className="app-symbolic-menu">
                <div className="dropdown" style={{ float: `right` }}>
                  {userProfileImage ? (
                    <UserImage src={userImage} />
                  ) : (
                    // <UserIcon
                    //   xmlns="http://www.w3.org/2000/svg"
                    //   width="16"
                    //   height="16"
                    //   fill="currentColor"
                    //   className="bi bi-person-circle"
                    //   viewBox="0 0 16 16"
                    // >
                    //   <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    //   <path
                    //     fillRule="evenodd"
                    //     d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    //   />
                    // </UserIcon>
                    <AnotherUser>
                      <PersonIcon
                        style={{ fill: "black", height: "20px", width: "20px" }}
                        className="person-icon"
                      />
                    </AnotherUser>
                  )}

                  <div className="dropdown-content">
                    {/* <div style={{ height: "100px" }} className="testing-div">
                    hello
                  </div> */}
                    <div className="first-manu-item">
                      {/* <span> */}
                      <div className="menu-profile-icon-container">
                        <div className="menu-profile-icon">
                          <UserIcon
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path
                              fillRule="evenodd"
                              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                            />
                          </UserIcon>

                          <div className="menu-profile-camera">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-camera-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* </span> */}
                      <div className="use-name-email">
                        {/* <p>user's name</p> */}
                        <p>{props.userInfo.fullName}</p>
                        <p>{props.userInfo.email}</p>
                      </div>
                    </div>

                    {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-book"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                  </svg>
                  <a href="/secure/classes">Something</a>
                </span>

                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-card-checklist"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                  </svg>
                  <a href="/secure/tests">Something</a>
                </span> */}

                    <span onClick={() => onClickMyCourses()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-video2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
                          fill="#000"
                        />
                        <path
                          d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z"
                          fill="#000"
                        />
                      </svg>
                      <a>My Courses</a>
                    </span>

                    <span onClick={() => onClickMyCertificates()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-folder"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
                      </svg>
                      <a>My Certificates</a>
                    </span>

                    <span onClick={() => onClickMyOrders()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-bag-check-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
                        />
                      </svg>
                      <a>My Orders</a>
                    </span>

                    <span onClick={() => onClickMyCompany()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-people-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                        />
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                      </svg>
                      <a>My Teams</a>
                    </span>

                    <span onClick={() => onClickNotifications()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                      <NotificationWrapper>
                        <NotificationBadge>
                          {props.numberOfNotifications}
                        </NotificationBadge>
                        <a style={{ paddingLeft: "16px" }}>Notifications</a>
                      </NotificationWrapper>
                    </span>
                    {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-gear-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                    <a>Settings</a>
                  </span> */}

                    <span
                      style={{
                        borderTop: "1px solid #bfbfbf",
                        justifyContent: "center",
                      }}
                      onClick={() => onClickLogOut()}
                    >
                      Logout
                    </span>

                    {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-door-open"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                    <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
                  </svg>
                  <a href="/secure/logout">Something</a>
                </span> */}
                  </div>
                </div>
              </div>
              {/* Dropdown menu end */}
            </UserImageContainer>
          ) : (
            <LoginSignUpContainer>
              <LoginButton onClick={() => onClickLogin()}>Login</LoginButton>
              <SignUpButton onClick={() => onClickSignUp()}>
                Signup
              </SignUpButton>
            </LoginSignUpContainer>
          )}
        </UserContainer>
      ) : (
        <div></div>
        // <LaraLogOutHolder>
        //   <LaraLogOutButton onClick={() => onClickLaraLogOut()}>
        //     Log Out
        //   </LaraLogOutButton>
        // </LaraLogOutHolder>
      )}
    </Container>
    // </div>
  );
}

export default Header;

const BetaVersion = styled.div`
  font-weight: 600;
  color: black;
  position: absolute;
  right: -30px;
  top: 10px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 70px;
  grid-template-columns: auto 300px !important;
  /* position: relative; */
  /* background-color: #09323b; */
  /* background-color: #eaeaea; */
  /* background-color: #999999; */
  /* background-color: #09323b; */
  /* background-color: #ffc2c7; */
  /* background-color: #d8a7b1; */
  /* background-color: #5488a5; */
  /* background-color: rgba(47, 72, 88, 0); */
  /* background-color: white; */
  /* background: url(${testImage}); */
  /* background-color: #f2f2f2; */
  background-color: rgba(242, 242, 242, 0.8);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23bbbbbb' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  color: #bbd5d2;
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  /* grid-template-columns: auto 205px; */

  &::before {
    content: "";
    /* background-image: url("https://placekitten.com/1200/800"); */
    /* background: url(${topPattern}) repeat center center; */
    /* background-size: cover; */
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
  }
`;

const AnotherUser = styled.div`
  /* background-color: rgba(47, 72, 88, 0); */
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 10px; */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 40%);
  }
`;

const LogoContainer = styled.div`
  width: fit-content;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 0px 30px 0px 20px;
  /* cursor: pointer; */
  height: 100%;
`;
const Logo = styled.img`
  cursor: pointer;
  height: 50px;
`;
const SearchContainer = styled.div`
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;

  /* width: 300px; */
`;
const Search = styled.input`
  /* position: absolute; */
  /* left: 0; */
  /* width: 300px; */
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 11.5px;

  /* border: 1px solid #d9d9d9; */

  border-right: none;
  border: 1px solid #09323b;
  outline: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  /* border-radius: 5px 0 0 5px; */
  &:focus {
    /* border: none; */
    outline: none;
  }
`;

const SearchButton = styled.button`
  /* position: absolute; */
  /* right: 0; */
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  border: none;
  /* border: 1px solid #d9d9d9; */
  outline: none;
  width: 40px;
  height: 40px;
  /* background-color: #5fb05f; */
  background-color: #09323b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchSVG = styled.svg`
  fill: white;

  path {
    /* stroke-width: 1;
    stroke: white; */
  }
`;

const UserContainer = styled.div`
  /* background-color: #f2f2f2; */
  /* background-color: #cccccc; */
  display: flex;
  align-items: center;
  justify-content: right;
  /* padding: 10px 10px; */
  /* border: 1px solid #e6e6e6; */
  /* border: 1px solid #5fb05f; */
  /* border-radius: 50px; */
  margin: 0px 20px;
  height: 100%;
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); */
  /* opacity: 80%; */
  /* box-shadow: inset 0px 0px 5px 5px rgba(10, 50, 58, 1); */
  /* transform: scaleX(2); */
`;
const UserName = styled.div``;
const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  /* background-color: red;
  background-blend-mode: multiply; */
`;

const CartSVG = styled.svg`
  width: 80%;
  height: 80%;

  /* object-fit: contain; */
  /* #09323B */
  /* fill: #04b16f; */
  /* fill: #09323b; */
  /* fill: #737373; */
  /* fill: white; */
  fill: #09323b;
`;

const UserImageContainer = styled.div`
  /* position: absolute; */
  /* background-color: #bbd5d2; */
  /* border: 1px solid #bbd5d2; */
  /* border: 1px solid #04b16f;
  
  padding: 2px; */
  /* width: fit-content;
  height: 100%; */

  border-radius: 50%;
  /* width: 40px; */
  /* height: 40px; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); */

  /* transform: scaleX(1); */
  /* transition: all 0.2s ease-in-out; */
  cursor: pointer;

  &:hover + .cart-container {
    opacity: 0;
  }

  /* &:hover {
    .person-icon {
      fill: rgb(95, 176, 95) !important;
    }
  } */
`;

const MenuCart = styled.div`
  /* border: 3px solid #09323b; */
  /* fill: #09323b; */
  background-color: white;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  /* padding: 4px; */
  padding: 9px 9px 9px 9px;
  cursor: pointer;
  position: relative;
  /* border: 1px solid #04b16f;
  border-radius: 50%; */
  /* padding: 0 20px 0 0px; */
  /* width: fit-content;
  height: 100%; */
  /* width: 27px;
  height: 27px; */
  display: flex;
  align-content: center;
  transition: all 0.2s ease-in-out;
  /* transform: translate(0, 0); */
  opacity: 1;
  justify-content: center;
  align-items: center;
  /* transform: translate(0, 0); */

  &:hover {
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 40%);

    /* .shopping-cart {
      fill: rgb(95, 176, 95) !important;
    } */
  }
`;

const UserIcon = styled.svg`
  width: 100%;
  height: 100%;
  object-fit: contain;

  /* fill: #04b16f; */
  /* fill: black; */
  /* fill: #737373; */
  /* fill: #5fb05f; */
  /* fill: #38668e; */
  /* fill: #031316; */
  /* fill: white; */
  fill: #09323b;
  /* border-radius: 50%; */
`;

const SearchIconContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CartCount = styled.div`
  position: absolute;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
  /* background-color: #09323b; */
  background-color: #ff6275;
  color: white;
  top: -9px;
  /* right: 8px; */
  right: -10px;
  /* width: 15px;
  height: 15px; */
  /* border-radius: 50%; */
  border-radius: 50px;
  font-weight: 600;
  font-size: 10px;
  /* border: 2px solid #f2f2f2; */
  /* border: 2px solid #09323b; */
  border: 2px solid white;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
`;

const CartNum = styled.p`
  white-space: nowrap;
  margin: 0px;
  padding: 0px;
  line-height: 6px;
`;

const StateManu = styled.div`
  width: 36px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: absolute;
  top: 70px;
  border-radius: 4px;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  z-index: 10000000000000000000000000000000000000000000000000;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(244, 238, 238, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
  transform: scale(0.98) translateX(0px) translateY(0px);

  /* &:hover {
    display: block;
  } */
`;
const FirstState = styled.div`
  cursor: pointer;
  padding: 5px;
  color: black;
  &:hover {
    color: #b58803;
    transition: all 0.2s ease-out;
  }

  /* &:active ${StateManu} {
    visibility: hidden;
    opacity: 0;
  } */

  /* &:active {
    font-size: 20px;
  } */
`;
const SecondState = styled.div`
  padding: 5px;
`;

const FilterButton = styled.button`
  cursor: pointer;

  position: relative;
  border-radius: 5px 0 0 5px;
  border: none;
  /* border: 1px solid #d9d9d9; */
  outline: none;
  width: 80px;
  height: 40px;
  /* background-color: #5fb05f; */
  background-color: #09323b;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${StateManu} {
    background: rgba(244, 238, 238, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition-delay: 0s;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    opacity: 1;
    visibility: visible;
    transform: scale(1) translateX(0px) translateY(0px);
  }

  /* &:active ${StateManu} {
    transition: all 0s ease-in;
    visibility: hidden;
    opacity: 0;
  } */

  /* &:hover {
    cursor: pointer;
  } */
`;
const FilterIconContainer = styled.div`
  color: white;
  /* font-size: 11px; */
`;

const MenuItems = styled.div`
  color: #09323b;
  font-weight: 600;
`;

const LoginSignUpContainer = styled.div`
  z-index: 1;
  display: flex;
`;
const LoginButton = styled.button`
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  padding: 9px 20px;
  /* margin: 2px; */
  margin-left: 10px;
  font-weight: 600;
  color: white;
  background-color: #09323b;
  border-radius: 0px 0px 0px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(9, 50, 59, 0.9);
  }
`;
const SignUpButton = styled.button`
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  padding: 8px 16px;
  /* margin: 2px; */
  font-weight: 600;
  color: black;
  background-color: #5fb05f;
  border-radius: 0px 10px 0px 0px;
  cursor: pointer;

  &:hover {
    background-color: rgba(95, 176, 95, 0.9);
  }
`;

const LogoutLogoContainer = styled.div`
  /* background-color: rgba(47, 72, 88, 0); */
  position: relative;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 40%);
  }
`;

const NotificationWrapper = styled.div`
  position: relative;
`;
const NotificationBadge = styled.div`
  position: absolute;
  padding: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #ff6275;
  color: white;
  top: -11px;
  right: -15px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 10px;
  border: 2px solid white;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 30%);
  white-space: nowrap;
  margin: 0px;

  line-height: 6px;
`;

const LaraLogOutHolder = styled.div`
  /* margin-top: 10px; */
  display: flex;
  margin: 15px;
  justify-content: right;
  align-items: center;
`;
const LaraLogOutButton = styled.div`
  z-index: 10;
  padding: 10px;
  cursor: pointer;
  background-color: #09323b;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: 600;
`;
