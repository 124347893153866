import axios from "axios";
import React, { useEffect, useState } from "react";

function ShowUserEmail(props) {
  const [userEmail, setUserEmail] = useState(null);
  useEffect(() => {
    if (props.userId) {
      axios.post("/returnUserEmail", { userId: props.userId }).then((data) => {
        setUserEmail(data.data);
      });
    }
  }, [props.userId]);
  return <div>{userEmail ? userEmail : <div></div>}</div>;
}

export default ShowUserEmail;
