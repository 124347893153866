import React, { useState } from "react";
import styled from "styled-components";
import logoImageWhite from "../Images/full-logo.png";
import logoDWSD from "../Images/logo_dwsd.png";
import logoGLWA from "../Images/logo-glwa.svg";
import { useNavigate } from "react-router-dom";

function Footer() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [larauser, setLaraUser] = useState(
    JSON.parse(localStorage.getItem("larauser"))
  );

  const history = useNavigate();
  const onClickContactUs = () => {
    history("/contactus");
  };

  const onClickAboutUs = () => {
    history("/aboutus");
  };

  const onClickPrivacy = () => {
    history("/privacy");
  };
  // here should be changed
  const onClickLaraLogin = () => {
    history("/laracheckstatus");
  };

  return (
    <Container>
      <ImportantCompanies>
        Companies trained through SafeT1st:
        <DWSD src={logoDWSD} />
        <GLWA src={logoGLWA} />
      </ImportantCompanies>
      <FooterContainer>
        <MainFooterContainer>
          <FooterLinksContainer>
            <PrivacyPolicy onClick={() => onClickPrivacy()}>
              Privacy Policy
            </PrivacyPolicy>
            <AboutUs onClick={() => onClickAboutUs()}>About Us</AboutUs>
            <ContactUs onClick={() => onClickContactUs()}>Contact Us</ContactUs>
            {/* {!user && !larauser ? ( */}
            <LaraLogin onClick={() => onClickLaraLogin()}>
              Certificate Verification
            </LaraLogin>
            {/* ) : ( */}
            {/* <div></div>
            )} */}
          </FooterLinksContainer>
        </MainFooterContainer>
        <LogoCopyRight>
          <Logo src={logoImageWhite} />
          <FooterAddressContainer>
            <Address>
              <CopyRight>&copy; 2022 eSpec Software</CopyRight>
              <p>12815 Premier Center Court</p>
              <p>Plymouth, MI 48170</p>
            </Address>
          </FooterAddressContainer>
        </LogoCopyRight>
      </FooterContainer>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  display: grid;
  grid-template-rows: 70px 230px;
  background: white;
`;
const ImportantCompanies = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 20px;
`;
const FooterContainer = styled.div`
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PrivacyPolicy = styled.div`
  padding-top: 10px;
  width: fit-content;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const AboutUs = styled.div`
  padding-top: 10px;
  width: fit-content;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const ContactUs = styled.div`
  padding-top: 10px;
  /* padding-bottom: 30px; */
  width: fit-content;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LaraLogin = styled.div`
  padding-top: 10px;
  padding-bottom: 30px;
  width: fit-content;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const LogoCopyRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
`;
const CopyRight = styled.div``;

const DWSD = styled.img`
  height: 60px;
  margin-right: 30px;
  margin-left: 30px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
`;
const GLWA = styled.img`
  height: 60px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
`;

const MainFooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const FooterLinksContainer = styled.div``;
const FooterAddressContainer = styled.div``;
const Address = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;

  p {
    font-size: 13.8px;
  }
`;
