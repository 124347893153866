import axios from "axios";
import React, { useEffect, useState } from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import styled from "@emotion/styled";

function ShowCoursePrice(props) {
  const [coursePrice, setCoursePrice] = useState(0);
  useEffect(() => {
    axios
      .post("/returnCoursePrice", { courseId: props.courseId })
      .then((data) => {
        const currentPrice = data.data;
        setCoursePrice(currentPrice * props.quantityProp);
        props.changeSubTotalProp(currentPrice * props.quantityProp);
        let tempArray = props.companyPriceArray;
        tempArray[props.index] = currentPrice;
        props.changePriceArray(tempArray);
      });
  }, []);

  return (
    <Container>
      <LocalOfferIcon />${coursePrice}
    </Container>
  );
}

export default ShowCoursePrice;

const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
