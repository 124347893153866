import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import safeTLogo from "../Images/full-logo.png";
import ShowCourseName from "../pages/showCourseName";
import certificateBadge from "../Images/shield_cert.png";
import presidentSignature from "../Images/CS_signature.png";
import secLogo from "../Images/espec-logo-copy.png";
import certTop from "../Images/CertOfComp-OH.png";
import certBottom from "../Images/bottom_cert_main.png";
import axios from "axios";
import "../App.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function Certificate() {
  const location = useLocation();
  const [currentDate, setCurrentData] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [stateName, setStateName] = useState("");
  const [certId, setCertId] = useState("");
  const [className, setClassName] = useState("");
  const [completeStateName, setCompleteStateName] = useState("");
  // const []

  const onClickPrintScreen = () => {
    const input = document.getElementById("certificate");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("l", "mm", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      pdf.margin = {
        horiz: 15,
        vert: 20,
      };

      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
      // pdf.addImage(
      //   imgData,
      //   "PNG",
      //   pdf.margin.horiz,
      //   pdf.margin.vert,
      //   canvasWidth,
      //   canvasHeight
      // );
      // pdf.addImage(imgData, "PNG", 0, 0, canvasWidth, canvasHeight);
      pdf.save("MyCertificate.pdf");
    });
  };

  const onClickPrintScreenOH = () => {
    const input = document.getElementById("OHCertificate");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
      pdf.save("MyCertificate.pdf");
    });
  };

  useEffect(() => {
    let states = [
      ["Alabama", "AL"],
      ["Alaska", "AK"],
      ["American Samoa", "AS"],
      ["Arizona", "AZ"],
      ["Arkansas", "AR"],
      ["Armed Forces Americas", "AA"],
      ["Armed Forces Europe", "AE"],
      ["Armed Forces Pacific", "AP"],
      ["California", "CA"],
      ["Colorado", "CO"],
      ["Connecticut", "CT"],
      ["Delaware", "DE"],
      ["District of Columbia", "DC"],
      ["Florida", "FL"],
      ["Georgia", "GA"],
      ["Guam", "GU"],
      ["Hawaii", "HI"],
      ["Idaho", "ID"],
      ["Illinois", "IL"],
      ["Indiana", "IN"],
      ["Iowa", "IA"],
      ["Kansas", "KS"],
      ["Kentucky", "KY"],
      ["Louisiana", "LA"],
      ["Maine", "ME"],
      ["Marshall Islands", "MH"],
      ["Maryland", "MD"],
      ["Massachusetts", "MA"],
      ["Michigan", "MI"],
      ["Minnesota", "MN"],
      ["Mississippi", "MS"],
      ["Missouri", "MO"],
      ["Montana", "MT"],
      ["Nebraska", "NE"],
      ["Nevada", "NV"],
      ["New Hampshire", "NH"],
      ["New Jersey", "NJ"],
      ["New Mexico", "NM"],
      ["New York", "NY"],
      ["North Carolina", "NC"],
      ["North Dakota", "ND"],
      ["Northern Mariana Islands", "NP"],
      ["Ohio", "OH"],
      ["Oklahoma", "OK"],
      ["Oregon", "OR"],
      ["Pennsylvania", "PA"],
      ["Puerto Rico", "PR"],
      ["Rhode Island", "RI"],
      ["South Carolina", "SC"],
      ["South Dakota", "SD"],
      ["Tennessee", "TN"],
      ["Texas", "TX"],
      ["US Virgin Islands", "VI"],
      ["Utah", "UT"],
      ["Vermont", "VT"],
      ["Virginia", "VA"],
      ["Washington", "WA"],
      ["West Virginia", "WV"],
      ["Wisconsin", "WI"],
      ["Wyoming", "WY"],
    ];
    if (location.state?.courseId) {
      axios
        .post("/returnCourseName", { courseId: location.state.courseId })
        .then((data) => {
          let stateNameTemp = data.data;
          setStateName(stateNameTemp);
          let classNameOnlyArr = stateNameTemp.split(" ");
          let completeStateNameOnly = stateNameTemp.split(" Petroleum")[0];
          let stateNameAbbr = "";
          let input = completeStateNameOnly.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
          for (var i = 0; i < states.length; i++) {
            if (states[i][0] == input) {
              stateNameAbbr = states[i][1];
            }
          }
          let classNameOnlyIndx = classNameOnlyArr.indexOf("Class") + 1;
          let classNameOnly = classNameOnlyArr[classNameOnlyIndx];
          setClassName(classNameOnly);
          setCompleteStateName(stateNameAbbr);
        });
    }
  }, [location.state]);

  // issue is here
  useEffect(() => {
    if (location.state?.createAt) {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let d = new Date(location.state.createAt);
      // ===============Certificate ID===============
      // console.log("Cert Origin:", d.valueOf());
      let certificateId = d.valueOf() - 45 * 31536000000;
      setCertId(certificateId);
      // ============================================

      let day = d.getDate();
      let monthName = months[d.getMonth()];
      let year = d.getFullYear();
      let post =
        day == 1 || day == 21 || day == 31
          ? "st, "
          : day == 2 || day == 22 || day == 32
          ? "nd, "
          : "th, ";
      let date = monthName + " " + day + post + year;

      // ================5 Years===================

      let expDate = "";
      if (className == "A" || className == "B" || className == "AB") {
        let nextYear = new Date(year + 5, d.getMonth(), day);
        let nextYearDay = nextYear.getDate();
        let nextYearMonthName = months[nextYear.getMonth()];
        let nextYearYear = nextYear.getFullYear();
        let postNextYear =
          day == 1 || day == 21 || day == 31
            ? "st, "
            : day == 2 || day == 22 || day == 32
            ? "nd, "
            : "th, ";

        expDate =
          nextYearMonthName + " " + nextYearDay + postNextYear + nextYearYear;
      } else {
        let nextYear = new Date(year + 2, d.getMonth(), day);
        let nextYearDay = nextYear.getDate();
        let nextYearMonthName = months[nextYear.getMonth()];
        let nextYearYear = nextYear.getFullYear();
        let postNextYear =
          day == 1 || day == 21 || day == 31
            ? "st, "
            : day == 2 || day == 22 || day == 32
            ? "nd, "
            : "th, ";

        expDate =
          nextYearMonthName + " " + nextYearDay + postNextYear + nextYearYear;
      }

      setCurrentData(date);
      setExpirationDate(expDate);
    }
  }, [location.state?.createAt, className]);

  return (
    <Container>
      {stateName != "" ? (
        stateName.split(" ")[0] !== "Ohio" ? (
          <OtherCertificateContainer>
            <OtherCertificate>
              <CertificateContainer id="certificate">
                <CertificateTop>
                  <CertifiedLogoContainer>
                    <CertifiedLogo src={certificateBadge} />
                  </CertifiedLogoContainer>
                  <Rectangle></Rectangle>
                  <ArrowDown></ArrowDown>
                  <ArrowDownSec></ArrowDownSec>
                  <ArrowDownThird></ArrowDownThird>
                </CertificateTop>

                <Content>
                  <UserNameContainer>
                    {location.state.fullName}
                  </UserNameContainer>
                  <CertificateIDContainer>
                    Certificate ID: {completeStateName}
                    {"-"}
                    {className}
                    {"-"}
                    {certId}
                  </CertificateIDContainer>

                  <Line1>
                    eSpec Software affirms that the name above has completed the
                  </Line1>
                  <Line2>
                    examination in accordance with state regulations and
                    standards for
                  </Line2>
                  <CourseNameContainer>
                    {/* <ShowCourseName
                    courseId={location.state.courseId}
                    changeState={setStateName}
                  /> */}
                    {stateName}
                    {/* <CourseNameContainer></CourseNameContainer> */}
                  </CourseNameContainer>
                  <CerificateID></CerificateID>

                  <CertificateExpiration>
                    <Current>Completed: {currentDate}</Current>
                    <Expiration>Expires: {expirationDate}</Expiration>
                  </CertificateExpiration>
                  <InnerContent>
                    <CertificateSignature>
                      <Header>
                        <Logo src={safeTLogo} />
                      </Header>
                      <Signature>
                        <SignatureContainer>
                          <ManagerSignature src={presidentSignature} />
                        </SignatureContainer>
                        <PresidentName>Casey Smith, President</PresidentName>
                      </Signature>

                      <CompanyLogo>
                        <SecLogo src={secLogo} />
                      </CompanyLogo>
                    </CertificateSignature>
                  </InnerContent>
                </Content>
                <Footer>
                  eSpec Software LLC. Phone:(800)274-4437 Address: 12815 Premier
                  Center Court Plymouth, MI 48170 Website:
                  https://safet1stonline.com{" "}
                </Footer>
              </CertificateContainer>
            </OtherCertificate>
            <PrintScreenContainer>
              <PrintScreenButton onClick={onClickPrintScreen}>
                Download Certificate
              </PrintScreenButton>
            </PrintScreenContainer>
          </OtherCertificateContainer>
        ) : (
          <OhioCertificateContainer>
            <OHCertificate>
              <OHCertificateContainer id="OHCertificate">
                <CertificateTopOH className="certificateTop">
                  {/* <LineTopOh className="certificateTop char3"> */}
                  {/* <svg viewBox="-190 30 700 150">
                <path
                  id="curve"
                  d="M73.2,148.6c4-6.1,65.5-96.8,178.6-5.6c111.3,1.2,170.8,90.3,175.1,97"
                />
                <text width="500">
                  <textPath href="#curve">Cerificate</textPath>
                </text>
              </svg> */}
                  {/* </LineTopOh> */}
                  {/* <LineTopOh
                className="certificateTop"
                style={{ position: "absolute", top: "230px" }}
              >
                of
              </LineTopOh>
              <LineTopOh className="certificateTop">Completion</LineTopOh> */}
                  <CertificateTopFont src={certTop}></CertificateTopFont>
                </CertificateTopOH>

                <Content>
                  <Line1OH>eSpec Software</Line1OH>
                  <Line2OH>A Certified Course Sponsor for</Line2OH>
                  <Line3OH>
                    "Bureau of Underground Storage Tank Regulations"
                  </Line3OH>

                  <CourseNameContainerOH>
                    {/* <ShowCourseName
                    courseId={location.state.courseId}
                    changeState={setStateName}
                  /> */}
                    {stateName.split(" ")[2] +
                      " " +
                      stateName.split(" ")[3] +
                      " " +
                      stateName.split(" ")[4] +
                      " Training"}
                    {/* <CourseNameContainer></CourseNameContainer> */}
                  </CourseNameContainerOH>
                  <Line4>Recongnizing</Line4>
                  <UserNameContainer>
                    {location.state.fullName}
                  </UserNameContainer>
                  <CerificateID></CerificateID>
                  <Line3>as having successfully completed</Line3>
                  <CourseNameContainerOH>{stateName}</CourseNameContainerOH>
                  <Line3>Presented</Line3>

                  <InnerContent>
                    <CertificateSignature>
                      {/* <Header>
                    <Logo src={safeTLogo} />
                  </Header> */}
                      <SignatureOhio>
                        <CertificateBottomImageContainer>
                          <CertificateBottomImage src={certBottom} />
                          <SignatureContent>
                            <CertificateExpiration>
                              <Current> {currentDate}</Current>
                            </CertificateExpiration>
                            <SignatureContainer>
                              <ManagerSignature src={presidentSignature} />
                            </SignatureContainer>
                            <PresidentName>
                              Casey Smith, President
                            </PresidentName>
                          </SignatureContent>
                        </CertificateBottomImageContainer>
                        {/* <SignatureContent>
                      <SignatureContainer>
                        <ManagerSignature src={presidentSignature} />
                      </SignatureContainer>
                      <PresidentName>Casey Smith, President</PresidentName>
                    </SignatureContent> */}
                      </SignatureOhio>

                      {/* <CompanyLogo>
                    <SecLogo src={secLogo} />
                  </CompanyLogo> */}
                    </CertificateSignature>
                  </InnerContent>
                </Content>
                <Footer>
                  eSpec Software LLC. Phone:(800)274-4437 Address: 12815 Premier
                  Center Court Plymouth, MI 48170 Website:
                  https://safet1stonline.com{" "}
                </Footer>
              </OHCertificateContainer>
            </OHCertificate>
            <PrintScreenContainer>
              <PrintScreenButton onClick={onClickPrintScreenOH}>
                Download Certificate
              </PrintScreenButton>
            </PrintScreenContainer>
          </OhioCertificateContainer>
        )
      ) : (
        <div>Loading...</div>
      )}
    </Container>
  );
}

export default Certificate;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const CertificateContainer = styled.div`
  max-width: 900px;
  max-height: 600px;
  min-width: 700px;
  width: 100%;
  border: 10px solid #189ab4;
  /* padding: 20px; */
  background-image: repeating-linear-gradient(
    11deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 1px,
    transparent 0px,
    transparent 4px
  );
  background-size: 8px;
`;

const OHCertificateContainer = styled.div`
  max-width: 900px;
  /* max-height: 600px; */
  min-width: 700px;
  width: 100%;
  border: 10px solid #189ab4;
  /* padding: 20px; */
  background-image: repeating-linear-gradient(
    11deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 1px,
    transparent 0px,
    transparent 4px
  );
  background-size: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 30px 0;
`;
const Content = styled.div``;

const InnerContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 30px 0;
  font-size: 11px;
`;
const Logo = styled.img`
  width: 150px;
`;

const UserNameContainer = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
`;
const CertificateIDContainer = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 200;
`;
const LineTopOh = styled.div``;
const Line1 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;
const Line2 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;

const Line3 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;

const Line1OH = styled.div`
  /* margin-top: 20px; */
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;
const Line2OH = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;

const Line3OH = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;

const Line4 = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 20px 0 0 0;
  /* font-weight: 800; */
  font-weight: 800;
`;

const CourseNameContainerOH = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 5px 0 5px 0;
  /* font-weight: 800; */
  font-weight: 800;
`;

const CerificateID = styled.div``;
const CertificateExpiration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
const CertificateSignature = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 10px 0 10px 0;
  width: 100%;
`;
const ManagerSignature = styled.img`
  width: 150px;
`;
const CertifiedLogo = styled.img`
  /* position: absolute;
  left: 0;
  top: 0; */

  /* height: 100%;
  width: 100%; */
  /* background-image: url(${certificateBadge});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
  /* -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1); */
  height: 150px;
  /* opacity: 0.2; */
  /* position: absolute; */
  /* background-image: url(certificateBadge); */
`;
const Current = styled.div`
  padding: 0 5px 0 5px;
`;
const Expiration = styled.div`
  border-left: 2px solid black;
  padding: 0 5px 0 5px;
`;

const PresidentName = styled.div`
  /* position: absolute; */
  border-top: 2px solid black;
  padding: 10px 0 10px 0;
  font-weight: 600;
`;

const SignatureContainer = styled.div`
  /* position: absolute; */
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Signature = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px 0 50px;
  /* position: relative;
  width: 100%; */
  /* background: url(${certBottom}); */
`;

const SignatureOhio = styled.div`
  /* display: flex;
  flex-direction: column;
  margin: 0 50px 0 50px; */
  position: relative;
  width: 100%;
  /* background: url(${certBottom}); */
`;

const CompanyLogo = styled.div``;
const SecLogo = styled.img`
  width: 150px;
`;
const CertifiedLogoContainer = styled.div`
  /* margin: 40px; */
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
`;

const CertificateTop = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  height: 150px;
`;

const CertificateTopOH = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 300px; */
  font-size: 50px;
  font-weight: 600;
  margin: 10px 100px 10px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
  /* font-family: "MedievalSharp", cursive; */
`;

const ArrowDown = styled.div`
  /* width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #f00; */
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -35px;
  z-index: 10;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #189ab4;
    transform: skew(0deg, 6deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #189ab4;
    transform: skew(0deg, -6deg);
  }
`;

const ArrowDownSec = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -95px;
  z-index: 1;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #5fb05f;
    transform: skew(0deg, 4deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #5fb05f;
    transform: skew(0deg, -4deg);
  }
`;

const ArrowDownThird = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -158px;
  z-index: 0;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: rgba(1, 1, 1, 1);
    opacity: 0.2;
    transform: skew(0deg, 3deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: rgba(1, 1, 1, 1);
    opacity: 0.2;
    transform: skew(0deg, -3deg);
  }
`;

const Rectangle = styled.div`
  height: 50px;
  width: 100%;
  background: #189ab4;
`;

// const CourseNameContainer=styled.div``

const CertificateTopFont = styled.img`
  width: 100%;
`;

const CertificateBottomImage = styled.img`
  width: 100%;
`;
const CertificateBottomImageContainer = styled.div`
  /* position: absolute; */
  position: relative;
  /* width: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
`;

const SignatureContent = styled.div`
  /* display: flex; */
  flex-direction: column;
  /* margin: 0 50px 0 50px; */
  /* position: absolute; */

  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrintScreenContainer = styled.div`
  margin-top: 20px;
`;
const PrintScreenButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  background-color: rgba(95, 176, 95, 0.9);
  transition: all 0.2s ease-in-out;
  color: white;
  border-radius: 4px;
  border: none;
  height: 40px;

  &:hover {
    background-color: rgba(95, 176, 95, 1);
  }

  &:disabled {
    background-color: rgba(1, 1, 1, 0.1);
    cursor: default;
  }

  span {
    height: 20px !important;
    width: 20px !important;
  }
`;
const OHCertificate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OtherCertificate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OtherCertificateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OhioCertificateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
