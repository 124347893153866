import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import PaginationTable from "./PaginationTable";
import CompanyMemeberCourseStatusTable from "./CompanyMemeberCourseStatusTable";
// import UserRolesContent from "./UserRolesContent";
import CompanyMemberRolesTable from "./CompanyMemberRolesTable";
import AddDeleteUserCompany from "./AddDeleteUserCompany";
import CourseRegistrationCompany from "./CourseRegistrationCompany";

import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TeamUserTransfer from "./TeamUserTransfer";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import firebaseApp from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TeamsBasket from "../pages/teamsBasket";
const auth = getAuth();

function CompanyMembers() {
  const { state } = useLocation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  // const [teamId, setTeamId] = useState();
  const [teamMembers, setteamMembers] = useState(null);
  const [teamMemberEmail, setTeamMemberEmail] = useState(null);
  const [clickStatus, setClickStatus] = useState(null);

  const [userRoleMenuColor, setUserRoleMenuColor] = useState("transparent");
  const [userAddDeleteMenuColor, setUserAddDeleteMenuColor] =
    useState("transparent");
  const [userRegisterationMenuColor, setUserRegisterationMenuColor] =
    useState("transparent");
  const [userStatusMenuColor, setUserStatusMenuColor] = useState("transparent");
  const [teamCartMenuColor, setTeamCartMenuColor] = useState("transparent");
  const [courseInfo, setCourseInfo] = useState(null);

  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [userTransferMenuColor, setUserTransferMenuColor] =
    useState("transparent");

  const [teamCartContent, setTeamCartContent] = useState(
    JSON.parse(localStorage.getItem("teamCart"))
  );

  const [teamId, setTeamId] = useState(null);
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onChangeTeamMemberEmail = (e) => {
    setTeamMemberEmail(e.target.value);
  };

  const onClickAddTeamMember = () => {
    if (isEmail(teamMemberEmail) && !isEmpty(teamMemberEmail)) {
      axios
        .post("/findTeamMember", { teamId: teamId, userEmail: teamMemberEmail })
        .then((data) => {
          // console.log("data size:", data.data.dataLength);
          if (data.data.dataLength === 0) {
            axios
              .post("/addTeamMember", {
                userEmail: teamMemberEmail,
                teamId: teamId,
                teamSupervisor: user.email,
              })
              .then((data) => {
                setteamMembers([...teamMembers, { useEmail: teamMemberEmail }]);
              });
          } else {
            // console.log("This user is in the team");
          }
        });
    } else {
      // console.log("The email is invalid!");
    }
  };

  const resetNumberOfTeamCartItems = (teamId) => {
    const db = firebaseApp.firestore();
    // console.log("Team IDID:", teamId);
    db.collection("TeamsCart")
      .where("teamId", "==", teamId)
      .get()
      .then((data) => {
        if (data.docs.length > 0) {
          db.collection("TeamsCart")
            .doc(data.docs[0].id)
            .collection("Cart")
            .orderBy("createdAt", "desc")
            .onSnapshot((snapshot) => {
              const items = [];
              snapshot.forEach((doc) => {
                const newData = doc.data();
                newData.cartId = data.docs[0].id;
                newData.itemId = doc.id;
                items.push(newData);
              });
              setNumberOfCartItems(snapshot.size);
              localStorage.setItem("teamCart", JSON.stringify(items));
            });
        } else {
          setNumberOfCartItems(0);
        }
      });
    // .doc(userEmail)
    // .collection("Notes")
    // .where("seen", "==", false)

    // .onSnapshot((snapshot) => {
    //   setNumberOfNotes(snapshot.size);
    // });
  };

  useEffect(() => {
    if (state?.teamId) {
      setTeamId(state?.teamId);
      // console.log("TeamId:", state.teamId);
      axios
        .post("/returnCompanyTeamMembers", {
          teamId: state.teamId,
        })
        .then((data) => {
          // console.log(data.data);
          setteamMembers(data.data);
          axios
            .post("/returnUserRole", {
              userEmail: user.email,
              teamId: state.teamId,
            })
            .then((userRole) => {
              // console.log("User role is importsnt:", userRole);
              setCurrentUserRole(userRole.data.role);
            });
        });
    }
  }, [state?.teamId]);

  useEffect(() => {
    if (state?.clickStatus) {
      setClickStatus(state.clickStatus);
    }
  }, [state?.clickStatus]);

  useEffect(() => {
    console.log("Status:", clickStatus);
  }, [clickStatus]);

  useEffect(() => {
    axios.get("/extractCourses").then((data) => {
      setCourseInfo(data.data);
    });
  }, []);

  useEffect(() => {
    if (teamId) {
      onAuthStateChanged(auth, (userm) => {
        if (userm) {
          resetNumberOfTeamCartItems(teamId);
          // console.log("Team IDdddd:", teamId);
        } else {
          // console.log("Team ID not:", teamId);
        }
      });
    }
  }, [teamId]);

  const onClickUserRole = () => {
    setClickStatus("roles");
    setUserRoleMenuColor("rgba(95, 176, 95, 0.5)");
    setUserAddDeleteMenuColor("transparent");
    setUserRegisterationMenuColor("transparent");
    setUserStatusMenuColor("transparent");
    setUserTransferMenuColor("transparent");
    setTeamCartMenuColor("transparent");
  };

  const onClickAddDeleteUser = () => {
    setClickStatus("add");
    setUserRoleMenuColor("transparent");
    setUserAddDeleteMenuColor("rgba(95, 176, 95, 0.5)");
    setUserRegisterationMenuColor("transparent");
    setUserStatusMenuColor("transparent");
    setUserTransferMenuColor("transparent");
    setTeamCartMenuColor("transparent");
  };

  const onClickUserRegisteration = () => {
    setClickStatus("registration");
    setUserRoleMenuColor("transparent");
    setUserAddDeleteMenuColor("transparent");
    setUserRegisterationMenuColor("rgba(95, 176, 95, 0.5)");
    setUserStatusMenuColor("transparent");
    setUserTransferMenuColor("transparent");
    setTeamCartMenuColor("transparent");
  };

  const onClickCourseStatus = () => {
    setClickStatus("status");
    setUserRoleMenuColor("transparent");
    setUserAddDeleteMenuColor("transparent");
    setUserRegisterationMenuColor("transparent");
    setUserStatusMenuColor("rgba(95, 176, 95, 0.5)");
    setUserTransferMenuColor("transparent");
    setTeamCartMenuColor("transparent");
  };

  const onClickUserTransfer = () => {
    setClickStatus("transfer");
    setUserRoleMenuColor("transparent");
    setUserAddDeleteMenuColor("transparent");
    setUserRegisterationMenuColor("transparent");
    setUserStatusMenuColor("transparent");
    setUserTransferMenuColor("rgba(95, 176, 95, 0.5)");
    setTeamCartMenuColor("transparent");
  };

  const onClickTeamCart = () => {
    setClickStatus("cart");
    setUserRoleMenuColor("transparent");
    setUserAddDeleteMenuColor("transparent");
    setUserRegisterationMenuColor("transparent");
    setUserStatusMenuColor("transparent");
    setUserTransferMenuColor("transparent");
    setTeamCartMenuColor("rgba(95, 176, 95, 0.5)");
  };

  return (
    <Container>
      <LeftSideManu>
        <CompanyName>{state.teamName}</CompanyName>
        {currentUserRole == "owner" || currentUserRole == "admin" ? (
          <TeamCart
            onClick={() => onClickTeamCart()}
            style={{ backgroundColor: teamCartMenuColor }}>
            <CartContainer>
              <ShoppingCartIcon style={{ marginRight: "5px" }} /> Team Cart
              <CartCount>
                <CartNum>{numberOfCartItems}</CartNum>
              </CartCount>
            </CartContainer>
          </TeamCart>
        ) : (
          <div></div>
        )}

        {/* <TeamOrderHistory></TeamOrderHistory> */}

        <UserRoles
          onClick={() => onClickUserRole()}
          style={{ backgroundColor: userRoleMenuColor }}>
          <SupervisedUserCircleRoundedIcon style={{ marginRight: "5px" }} />
          User Roles
        </UserRoles>

        {currentUserRole == "owner" ? (
          <AddDeleteUsers
            onClick={() => onClickAddDeleteUser()}
            style={{ backgroundColor: userAddDeleteMenuColor }}>
            <AddCircleRoundedIcon style={{ marginRight: "5px" }} />
            Add/Delete Users
          </AddDeleteUsers>
        ) : (
          <div></div>
        )}
        {currentUserRole == "owner" || currentUserRole == "admin" ? (
          <div>
            <CourseRegistration
              onClick={() => onClickUserRegisteration()}
              style={{ backgroundColor: userRegisterationMenuColor }}>
              <HowToRegRoundedIcon style={{ marginRight: "5px" }} />
              Course Registration
            </CourseRegistration>
            <CourseStatus
              onClick={() => onClickCourseStatus()}
              style={{ backgroundColor: userStatusMenuColor }}>
              <AssignmentTurnedInIcon style={{ marginRight: "5px" }} />
              Course Status
            </CourseStatus>
            <UserTransfer
              onClick={() => onClickUserTransfer()}
              style={{ backgroundColor: userTransferMenuColor }}>
              <TransferWithinAStationIcon style={{ marginRight: "5px" }} />
              User Transfer
            </UserTransfer>
          </div>
        ) : (
          <div></div>
        )}
      </LeftSideManu>
      {/* needs some changes */}
      <LeftSideMenuContent>
        {clickStatus &&
        teamMembers &&
        teamMembers?.length !== 0 &&
        currentUserRole ? (
          clickStatus == "roles" ? (
            <UserRolesContent>
              <CompanyMemberRolesTable
                data={teamMembers}
                role={currentUserRole}
              />
            </UserRolesContent>
          ) : clickStatus == "add" ? (
            <AddDeleteUsersContent>
              <AddDeleteUserCompany
                data={teamMembers}
                teamIdProp={state?.teamId}
                role={currentUserRole}
              />
            </AddDeleteUsersContent>
          ) : clickStatus == "registration" ? (
            <CourseRegistrationContent>
              <CourseRegistrationCompany
                data={teamMembers}
                teamIdProp={state?.teamId}
                courseInformation={courseInfo}
              />
            </CourseRegistrationContent>
          ) : clickStatus == "status" ? (
            <CourseStatusContent>
              {/* {teamMembers && teamMembers?.length !== 0 ? ( */}
              <CompanyMemeberCourseStatusTable data={teamMembers} />
              {/* ) : (
                <div></div>
              )} */}
            </CourseStatusContent>
          ) : clickStatus == "transfer" ? (
            <UserTransferContent>
              <TeamUserTransfer
                teamUsers={teamMembers}
                currentTeamId={state?.teamId}
                allTeams={state?.allTeamsID}
              />
            </UserTransferContent>
          ) : (
            <TeamCartItemHolder>
              <TeamsBasket currentTeamId={state?.teamId} />
            </TeamCartItemHolder>
          )
        ) : (
          <div></div>
        )}
      </LeftSideMenuContent>
    </Container>
  );
}

export default CompanyMembers;

const Container = styled.div`
  /* padding: 25px; */
  box-sizing: border-box;
  padding: 70px 0px 0px 0px;
  display: grid;
  grid-template-columns: 250px auto;
  height: 100%;
`;

const AddTeamMember = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 20px;
`;
const AddTeamMemberInput = styled.input`
  padding: 9px;
  margin-right: 5px;
  /* width: 300px; */
  width: 100%;
`;
const AddTeamMemberButton = styled.button`
  padding: 7px;
  width: 50px;
  margin-right: 5px;
`;
const AddTeamMembersButton = styled.button`
  padding: 5px;
  padding-top: 9px;
  width: 50px;
`;

const LeftSideManu = styled.div`
  /* padding: 10px; */
  /* position: fixed; */
  box-shadow: -6px 8px 18px 1px black;
  width: 250px;
  height: 100%;
`;
const LeftSideMenuContent = styled.div`
  /* border-left: 1px solid; */
  height: 100%;
`;

const UserRoles = styled.div`
  display: flex;
  align-items: center;

  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;
const AddDeleteUsers = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;
const CourseRegistration = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;
const CourseStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;

const UserTransfer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;

const UserRolesContent = styled.div``;
const AddDeleteUsersContent = styled.div``;
const CourseRegistrationContent = styled.div``;
const CourseStatusContent = styled.div``;
const UserTransferContent = styled.div``;
const TeamCartItemHolder = styled.div``;
const CompanyName = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(95, 176, 95, 1) !important;

  padding: 15px 5px 15px 20px;
  cursor: default;
  font-weight: 600;
  font-size: 18px;
  color: lightgray;
  /* cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  } */
`;

const TeamCart = styled.div`
  display: flex;
  align-items: center;

  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;

const TeamOrderHistory = styled.div`
  display: flex;
  align-items: center;

  padding: 15px 5px 15px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background-color: rgb(95, 176, 95, 0.5) !important;
  }
`;

const CartCount = styled.div`
  position: absolute;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
  /* background-color: #09323b; */
  background-color: #ff6275;
  color: white;
  top: -9px;
  /* right: 8px; */
  right: -20px;
  /* width: 15px;
  height: 15px; */
  /* border-radius: 50%; */
  border-radius: 50px;
  font-weight: 600;
  font-size: 10px;
  /* border: 2px solid #f2f2f2; */
  /* border: 2px solid #09323b; */
  border: 2px solid white;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
`;

const CartNum = styled.p`
  white-space: nowrap;
  margin: 0px;
  padding: 0px;
  line-height: 6px;
`;

const CartContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
