import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowCourseStatus from "./ShowCourseStatus";
import ShowCompanyUserRole from "./ShowCompanyUserRole";
import axios from "axios";
import ShowTeamName from "../pages/showTeamName";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@mui/material/TextField";

const columns = [
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "delete", label: "Delete User" }, //, minWidth: 100
];

function AddDeleteUserCompany(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [recordProps, setRecordProps] = useState(props.data);
  const [courseNameEntered, setCourseNameEntered] = useState("");
  const [courseStatusSelected, setCourseStatusSelected] = useState("");
  const [changesApplied, setChangesApplied] = useState([]);
  const [userInvite, setUserInvite] = useState("");
  const [userInviteFullName, setUserInviteFullName] = useState("");
  const [inviteTeamId, setInviteTeamId] = useState(props.teamIdProp);
  const [teamName, setTeamName] = useState();

  const [inviteTeamButtonVisible, setInviteTeamButtonVisible] = useState(false);
  const [inviteMessage, setInviteMessage] = useState("");

  // ========================================================

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [searchedRecords, setSearchedRecords] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ==========================================================

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isPassword = (password) => {
    const re =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_])(?=.*[A-Z]{2})(?=.*[a-z]{2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    if (password.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string == "" || string?.trim() == "") return true;
    else return false;
  };

  const onChangeEmailSearch = (e) => {
    if (recordProps) {
      // setEmailSelected(e.target.value);
      // setCourseStatusSelected(e.target.value);
      let searchKey = e.target.value;
      let tempAllArr = [...mainRecordProps];
      let tempSearchArr = [...searchedRecords];
      // console.log("Course status selected:", courseStatusSelected);
      if (
        courseStatusSelected.trim() === "" ||
        courseStatusSelected === "all"
      ) {
        if (searchKey.trim() === "") {
          setSearchedRecords(tempAllArr);
          // console.log("You selected All");
        } else {
          // console.log("Course status selected ma inja inja");
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) >
              -1
            );
          });
          // console.log("You returned:", filtered);
          setSearchedRecords(filtered);
        }
      } else {
        var filtered = tempAllArr.filter(function (value, index, arr) {
          return (
            value.role === courseStatusSelected &&
            value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) > -1
          );
        });
        // console.log("You returned:", filtered);
        setSearchedRecords(filtered);
      }
    }
    // if (recordProps) {
    //   let newArray = [...recordProps];
    //   for (var i = 0; i < newArray.length; i++) {
    //     if (
    //       newArray[i].userEmail
    //         .toUpperCase()
    //         .indexOf(e.target.value.toUpperCase()) > -1
    //     ) {
    //       newArray[i].displayEmail = false;
    //     } else {
    //       newArray[i].displayEmail = true;
    //     }
    //   }
    //   setRecordProps(newArray);
    // }
  };

  const onChangeCourseSearch = (e) => {
    if (recordProps) {
      // console.log("Entering something:", e.target.value);
      setCourseNameEntered(e.target.value);
    }
  };

  const onChangeStatusSearch = (e) => {
    if (recordProps) {
      setCourseStatusSelected(e.target.value);
    }
  };

  const onClickSubmitChanges = () => {
    if (changesApplied && changesApplied.length > 0) {
      axios.post("/userRoleChanging", {
        indexArray: changesApplied,
        allData: recordProps,
      });
    }
  };

  const onClickUserDelete = (useremail, teamid, index) => {
    let tempArr = [...recordProps];
    tempArr.splice(index, 1);
    setRecordProps(tempArr);
    axios.post("/deleteUserFromTeam", { userEmail: useremail, teamId: teamid });
  };

  const onClickInviteUserButton = () => {
    if (
      !isEmpty(userInvite) &&
      isEmail(userInvite) &&
      !isEmpty(userInviteFullName)
    ) {
      const userFullName = userInviteFullName;
      const regex = /^[a-zA-Z\s]+$/;
      if (regex.test(userFullName)) {
        setInviteTeamButtonVisible(true);

        return axios
          .post("/returnTeamName", { teamId: inviteTeamId })
          .then((data) => {
            setTeamName(data.data);
          });
      } else {
        setInviteMessage("Please enter valid full name.");
      }
      // console.log("it is clicked", inviteTeamId);
    } else if (isEmpty(userInvite) || isEmpty(userInviteFullName)) {
      setInviteMessage("Please fill out the username and email.");
    } else if (!isEmpty(userInvite) && !isEmail(userInvite)) {
      setInviteMessage("Please enter valid email.");
    }
  };

  useEffect(() => {
    if (
      teamName &&
      !isEmpty(userInvite) &&
      isEmail(userInvite) &&
      !isEmpty(userInviteFullName)
    ) {
      // const abortController = new AbortController();
      // console.log("email:", userInvite);
      // console.log("teamId:", inviteTeamId);
      // console.log("fullName:", user.fullName);
      // console.log("teamName:", teamName);
      const InviteeEmail = userInvite.toLowerCase();
      const InviteeFullName = userInviteFullName.replace(/\b\w/g, (char) =>
        char.toUpperCase()
      );

      axios
        .post("/inviteToTeam", {
          email: InviteeEmail,
          teamId: inviteTeamId,
          fullName: user.fullName,
          teamName: teamName,
          senderEmail: user.email,
          userName: InviteeFullName,
        })
        .then((data) => {
          // console.log(data);
          setUserInvite("");
          setUserInviteFullName("");
          setInviteTeamButtonVisible(false);
          setInviteMessage(data.data.message);
          // if (data.data.message == "s") {
          //   setInviteMessage("Invitation note is sent to the user.");
          // } else if (data.data.message == "u") {
          //   setInviteMessage("The user doesn't have an account.");
          // } else {
          //   setInviteMessage("System error.");
          // }
        })
        .catch((err) => {
          // console.log(err);
          setUserInvite("");
          setInviteTeamButtonVisible(false);
        });
    }
  }, [teamName]);

  useEffect(() => {
    if (props.data) {
      setRecordProps(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (recordProps) {
      let tempArr = [...recordProps];
      // console.log("Team members:", recordProps);
      tempArr.forEach((item, key) => {
        item.id = key;
      });
      // console.log("Temp array:", tempArr);
      setMainRecordProps(tempArr);
    }

    return () => {
      setMainRecordProps(null);
    };
  }, [recordProps]);

  useEffect(() => {
    if (mainRecordProps) {
      setSearchedRecords([...mainRecordProps]);
    }
  }, [mainRecordProps]);

  useEffect(() => {
    // console.log("My Indexes:", changesApplied);
  }, [changesApplied]);

  useEffect(() => {
    // console.log("Invite user:", userInvite);
  }, [userInvite]);

  useEffect(() => {
    if (props.teamIdProp) {
      setInviteTeamId(props.teamIdProp);
    }
  }, [props.teamIdProp]);

  useEffect(() => {
    if (inviteTeamId) {
      setInviteTeamId(props.teamIdProp);
    }
  }, [props.teamIdProp]);

  useEffect(() => {
    return () => {
      setMainRecordProps(null);
      setRecordProps(null);
    };
  }, []);

  return (
    <Container>
      <InviteUserContainer>
        <TextField
          id="outlined-basic"
          label="Fullname"
          variant="outlined"
          onChange={(e) => setUserInviteFullName(e.target.value)}
          onFocus={() => setInviteMessage("")}
          value={userInviteFullName}
        />
        {/* <InviteUserFullName
          onChange={(e) => setUserInviteFullName(e.target.value)}
          value={userInviteFullName}
          onFocus={() => setInviteMessage("")}
          placeholder="Fullname..."
        ></InviteUserFullName> */}
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          onChange={(e) => setUserInvite(e.target.value)}
          onFocus={() => setInviteMessage("")}
          value={userInvite}
        />
        {/* <InviteUserEmail
          onChange={(e) => setUserInvite(e.target.value)}
          value={userInvite}
          onFocus={() => setInviteMessage("")}
          placeholder="Email..."></InviteUserEmail> */}
        {!inviteTeamButtonVisible ? (
          <InviteUserButton
            onClick={() => onClickInviteUserButton()}
            disabled={inviteTeamButtonVisible}>
            Invite User
          </InviteUserButton>
        ) : (
          <InviteUserButton
            onClick={() => onClickInviteUserButton()}
            disabled={inviteTeamButtonVisible}>
            <CircularProgress color="inherit" />
          </InviteUserButton>
        )}
      </InviteUserContainer>
      <ErrorMessage>{inviteMessage}</ErrorMessage>
      <CustomTable>
        <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
          {/* <StatusSearchContainer>
            <StatusSearch
              defaultValue="select"
              onChange={(e) => onChangeStatusSearch(e)}
            >
              <option value="all">All</option>
              <option value="owner">Owner</option>
              <option value="admin"> Admin</option>
              <option value="member">Member</option>
            </StatusSearch>
            <StatusSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </StatusSearchButton>
          </StatusSearchContainer> */}
        </SearchTable>
        {/* =============The main pagination table is here============= */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "350px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedRecords ? (
                  searchedRecords
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            display:
                              !row?.displayEmail &&
                              !row?.displayCourse &&
                              !row?.displayStatus
                                ? ""
                                : "none",
                          }}>
                          {/* {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })} */}
                          <TableCell>{row.userEmail}</TableCell>
                          <TableCell>
                            <DeleteCompanyUserButton
                              onClick={() =>
                                onClickUserDelete(
                                  row.userEmail,
                                  row.teamId,
                                  row.id
                                )
                              }
                              disabled={
                                props.role == "member" ||
                                props.role == "admin" ||
                                row.userEmail !== user.email
                                  ? false
                                  : true
                              }>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg> */}
                              <DeleteIcon
                                color={
                                  props.role == "member" ||
                                  props.role == "admin" ||
                                  row.userEmail !== user.email
                                    ? ""
                                    : "disabled"
                                }
                              />
                            </DeleteCompanyUserButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={
              searchedRecords
                ? searchedRecords?.length
                : searchedRecords?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* =========================================================== */}
        {/* <Header>
          <FirstHeader>Student Email</FirstHeader>
          <ThirdHeader>Delete User</ThirdHeader>
        </Header>
        <TableBody>
          {recordProps ? (
            recordProps.map((item, key) => (
              <TableRow
                style={{
                  display:
                    !item?.displayEmail &&
                    !item?.displayCourse &&
                    !item?.displayStatus
                      ? ""
                      : "none",
                }}
              >
                <FirstColumn>{item.userEmail}</FirstColumn>
                <ThirdColumn>
                  <DeleteCompanyUserButton
                    onClick={() =>
                      onClickUserDelete(item.userEmail, item.teamId, key)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </DeleteCompanyUserButton>
                </ThirdColumn>
              </TableRow>
            ))
          ) : (
            <div></div>
          )}
        </TableBody> */}
      </CustomTable>
    </Container>
  );
}

export default AddDeleteUserCompany;

const Container = styled.div`
  padding: 20px;
`;
const CustomTable = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
const Header = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
`;
// const TableBody = styled.div`
//   overflow: auto;
// `;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
// const TableRow = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;

`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 35px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  background-color: green;
  border: none;
  outline: none;
`;

const DeleteCompanyUserButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
`;
const InviteUserContainer = styled.div`
  /* margin-bottom: 20px; */
  display: grid;
  grid-template-columns: auto auto 150px;
  grid-gap: 5px;
`;
const InviteUserEmail = styled.input`
  padding: 5px;
  margin-right: 5px;
`;
const InviteUserFullName = styled.input`
  padding: 5px;
  margin-right: 5px;
`;
const InviteUserButton = styled.button`
  cursor: pointer;
  padding: 5px;
  background-color: rgba(95, 176, 95, 0.9);
  transition: all 0.2s ease-in-out;
  color: white;
  border-radius: 4px;
  border: none;
  // height: 40px;

  &:hover {
    background-color: rgba(95, 176, 95, 1);
  }

  &:disabled {
    background-color: rgba(1, 1, 1, 0.1);
    cursor: default;
  }

  span {
    height: 20px !important;
    width: 20px !important;
  }
`;

const ErrorMessage = styled.div`
  margin-bottom: 15px;
  color: red;
  font-size: 12px;
  height: 10px;
`;
