import React, { useEffect, useState } from "react";
import axios from "axios";

import { Counter } from "./features/counter/Counter";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Landing from "./Components/Landing";
// import LandingTest from "./Components/LandingTest";
import Header from "./Components/Header";
import Basket from "./pages/basket";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Team from "./Components/Team";
import TeamMembers from "./Components/TeamMembers";
import MyCourses from "./Components/MyCourses";
import CourseContent from "./Components/CourseContent";
import Orders from "./Components/Orders";
import Success from "./pages/success";
import MyCompany from "./Components/MyCompany";
import CompanyMembers from "./Components/CompanyMembers";
import MyCertificates from "./Components/MyCertificates";
import MyNotifications from "./Components/MyNotifications";
import ITTNotification from "./Components/ITTNotification";
import ITSNotification from "./Components/ITSNotification";
import SimpleNotification from "./Components/SimpleNotification";
import Certificate from "./Components/Certificate";
import EmailConfirmed from "./Components/EmailConfirmed";
import TestEmailTemplate from "./Components/TestEmailTemplate";
import ForgetPassword from "./Components/ForgetPassword";
import Footer from "./Components/Footer";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import PasswordReset from "./Components/PasswordReset";

import firebaseApp from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LaraAdminLogin from "./Components/LaraAdminLogin";
import LaraCheckUserCourseStatus from "./Components/LaraCheckUserCourseStatus";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// axios.defaults.baseURL =
//   "https://us-central1-safet1st-95fb6.cloudfunctions.net/adminpanel";

axios.defaults.baseURL =
  "https://us-central1-safet1st-95fb6.cloudfunctions.net/api";

// import LandingTest from "./Components/LandingTest";

const auth = getAuth();

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [larauser, setLaraUser] = useState(
    JSON.parse(localStorage.getItem("larauser"))
  );
  // const [user, setUser] = useState();
  const [state, setState] = useState(null);
  const [stateName, setStateName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [landingKeyReset, setLandingKeyReset] = useState(0);
  const [numberOfNotes, setNumberOfNotes] = useState(0);
  const [reset, setReset] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    if (localStorage.getItem("larauser")) {
      setLaraUser(JSON.parse(localStorage.getItem("larauser")));
    }
  }, [localStorage.getItem("larauser")]);

  useEffect(() => {
    // console.log("reset:", reset);
  }, [reset]);

  const reserNumberOfNotes = (userEmail) => {
    const db = firebaseApp.firestore();
    db.collection("Notifications")
      .doc(userEmail)
      .collection("Notes")
      .where("seen", "==", false)

      .onSnapshot((snapshot) => {
        setNumberOfNotes(snapshot.size);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (userm) => {
      if (userm) {
        const uid = userm.uid;
        reserNumberOfNotes(userm.email);
      } else {
      }
    });
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     localStorage.setItem("user", JSON.stringify(user));
  //   }
  // }, [user]);

  return (
    <Router>
      {reset && !user ? (
        <Container>
          {/* <Main> */}
          <Routes>
            <Route
              exact
              path="/signup"
              element={<Signup passUser={setUser} />}
            />
            <Route exact path="/forgotpasswort" element={<ForgetPassword />} />
            <Route exact path="/login" element={<Login passUser={setUser} />} />

            {/* {larauser ? ( */}

            {/* <Route
                path="/laracheckstatus"
                element={
                  <Container>
                    <Header
                      userInfo={user}
                      passUser={setUser}
                      state={state}
                      passState={setStateName}
                      changeSearchKey={setSearchKey}
                      resetCourseKey={setLandingKeyReset}
                      keyReset={landingKeyReset}
                      passLaraUser={larauser}
                    />
                    <LaraCheckUserCourseStatus
                      changeReset={setReset}
                      propReset={reset}
                      changeLaraUser={setLaraUser}
                    />
                    <Footer
                      passLaraUser={larauser}
                      changeReset={setReset}
                      propReset={reset}
                      changeLaraUser={setLaraUser}
                    />
                  </Container>
                } */}
            {/* /> */}
            {/* // ) : (
              //   <Route
              //     path="/laralogin"
              //     element={
              //       <LaraAdminLogin
              //         changeReset={setReset}
              //         propReset={reset}
              //         changeLaraUser={setLaraUser}
              //       />
              //     }
              //   />
              // )} */}

            <Route path="/emailconfirmation" element={<EmailConfirmed />} />
            <Route path="/testemailtemple" element={<TestEmailTemplate />} />
            <Route path="/passwordreset" element={<PasswordReset />} />

            <Route
              exact
              path="/basket"
              element={
                <div>
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <Basket />
                  </Main>

                  <Footer />
                </div>
              }
            />

            <Route
              exact
              path="/"
              element={
                <div
                // style={{
                //   display: "grid",
                //   gridTemplateRows: "70px auto 300px",
                // }}
                >
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <Landing
                      changeState={setState}
                      useStateName={stateName}
                      passSearchKey={searchKey}
                      keyReset={landingKeyReset}
                      passUser={setUser}
                    />
                  </Main>

                  <Footer />
                </div>
              }
            />
            <Route
              exact
              path="/contactus"
              element={
                <div>
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <ContactUs />
                  </Main>

                  <Footer />
                </div>
              }
            />

            <Route
              path="/laracheckstatus"
              element={
                <div
                  style={{
                    position: "relative",
                    display: "grid",
                    gridTemplateRows: "auto 300px",
                    height: "100vh",
                  }}>
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <LaraCheckUserCourseStatus
                      changeReset={setReset}
                      propReset={reset}
                      changeLaraUser={setLaraUser}
                    />
                  </Main>

                  <Footer />
                </div>
              }
            />

            <Route
              exact
              path="/aboutus"
              element={
                <div>
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <AboutUs />
                  </Main>

                  <Footer />
                </div>
              }
            />
            <Route
              exact
              path="/privacy"
              element={
                <div>
                  <Header
                    userInfo={user}
                    passUser={setUser}
                    state={state}
                    passState={setStateName}
                    changeSearchKey={setSearchKey}
                    resetCourseKey={setLandingKeyReset}
                    keyReset={landingKeyReset}
                  />
                  <Main>
                    <PrivacyPolicy />
                  </Main>

                  <Footer />
                </div>
              }
            />
          </Routes>
          {/* </Main> */}
        </Container>
      ) : (
        <Container>
          <Header
            userInfo={user}
            passUser={setUser}
            state={state}
            passState={setStateName}
            changeSearchKey={setSearchKey}
            resetCourseKey={setLandingKeyReset}
            keyReset={landingKeyReset}
            numberOfNotifications={numberOfNotes}
          />
          {/* <HeaderTest /> */}
          <Main>
            <Routes>
              <Route path="/myorders" element={<Orders />} />

              <Route path="/coursecontent" element={<CourseContent />} />
              <Route path="/teammembers" element={<TeamMembers />} />
              <Route path="/mycourses" element={<MyCourses />} />
              {/* <Route path="/team" element={<Team />} /> */}
              <Route path="/basket" element={<Basket />} />
              <Route path="/success" element={<Success />} />
              <Route path="/myteam" element={<MyCompany />} />
              <Route path="/team" element={<CompanyMembers />} />
              <Route path="/mycertificates" element={<MyCertificates />} />
              <Route path="/notifications" element={<MyNotifications />} />
              <Route path="/certificate" element={<Certificate />} />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route exact path="/aboutus" element={<AboutUs />} />
              <Route exact path="/privacy" element={<PrivacyPolicy />} />

              <Route
                path="/laracheckstatus"
                element={
                  <LaraCheckUserCourseStatus
                    changeReset={setReset}
                    propReset={reset}
                    changeLaraUser={setLaraUser}
                  />
                }
              />

              <Route
                path="/teaminvitenotification"
                element={<ITTNotification />}
              />
              <Route
                path="/shareinvitenotification"
                element={<ITSNotification />}
              />
              <Route
                path="/simplenotification"
                element={<SimpleNotification />}
              />
              <Route
                path="/"
                element={
                  <Landing
                    changeState={setState}
                    useStateName={stateName}
                    passSearchKey={searchKey}
                    keyReset={landingKeyReset}
                    passUser={setUser}
                  />
                }
              />
            </Routes>
          </Main>
          <Footer />
        </Container>
      )}
    </Router>
  );
}

export default App;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto 300px;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  /* background-color: #ddffe7; */
`;
const Main = styled.div`
  background-color: #f2f2f2;
  /* height: 100%; */
`;

const MainPage = styled.div``;
