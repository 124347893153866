import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCount } from "./counterAPI";

const initialState = {
  items: [],
  total: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addItems: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.value += 1;
      let newTotal = state.total;
      // newTotal = Number.parseFloat(newTotal) + action.payload.props.price;
      newTotal = Number.parseFloat(newTotal) + action.payload.price;
      state.total = newTotal.toFixed(2);
      state.items = [...state.items, action.payload];
    },
    deleteItems: (state, action) => {
      // state.value -= 1;

      const index = state.items.findIndex(
        // (basketItem) => basketItem.props.id === action.payload
        (basketItem) => basketItem.id === action.payload
      );

      let newBasket = [...state.items];
      let newTotal = state.total;
      // newTotal = Number.parseFloat(newTotal) - newBasket[index].props.price;
      newTotal = Number.parseFloat(newTotal) - newBasket[index].price;
      state.total = newTotal.toFixed(2);
      if (index >= 0) {
        newBasket.splice(index, 1);
      }

      state.items = newBasket;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.value += action.payload;
  //     });
  // },
});

export const { addItems, deleteItems } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.items;
export const selectTotal = (state) => state.counter.total;
// export const selectTotal = (state) =>
//   state.counter.items.recude((total, item) => total + item.price, 0);

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };
///

export default counterSlice.reducer;
