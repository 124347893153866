import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "@emotion/styled";
import ShowCoursePrice from "./showCoursePrice";
import ShowCourseName from "./showCourseName";
import ShowUserEmail from "./showUserEmail";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import Big from "big.js";

import firebaseApp from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { loadStripe } from "@stripe/stripe-js";
const stripePromiseTestMode = loadStripe(
  "pk_test_51HC8ruLYpiPz9FF416QMwOiY1LJDy6oF3JqZ5YuATtIreOoh6ppEnQk5FQlhFzcpWumBZTefBvznFz595REDm48g00uktcs3u7"
);

const stripePromiseLiveMode = loadStripe(
  "pk_live_51HC8ruLYpiPz9FF4gF6Suk6enWcV7kocqHJdU1jMWm1sck1SlJCrsUftYPDDwWoZ8L6Txo9olw0l6Yv5NAZs2eDJ00KJiF3o00"
);

const stripePK = stripePromiseLiveMode;
const auth = getAuth();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: "flex",
  },
  detail: {
    width: "100%",
  },
}));

function TeamsBasket(props) {
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [teamCartItem, setTeamCartItem] = useState(
    JSON.parse(localStorage.getItem("teamCart"))
  );

  const [subTotal, setSubTotal] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [counter, setCounter] = useState(0);
  const [priceArray, setPriceArray] = useState([]);
  const [courseNameArray, setCourseNameArray] = useState([]);

  const onClickDeleteItem = (cartId, itemId) => {
    const db = firebaseApp.firestore();

    onAuthStateChanged(auth, (userm) => {
      if (userm) {
        db.collection("TeamsCart")
          .doc(cartId)
          .collection("Cart")
          .doc(itemId)
          .delete()
          .then(() => {
            setSubTotal(0);
          })
          .then(() => {
            setCounter(counter + 1);
          });
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("teamCart")) {
      //   const count=0;
      const items = JSON.parse(localStorage.getItem("teamCart"));
      setTeamCartItem(items);
      // items.forEach((eachItem)=>{
      //     count+=eachItem.
      // })
    }
  }, [localStorage.getItem("teamCart")]);

  useEffect(() => {
    if (currentPrice > 0) {
      //   let newPrice = currentPrice;
      //   let newSubtotal = subTotal;

      //   let realNewPrice = new Big(newPrice);
      //   let something = new Big(newSubtotal);
      //   realNewPrice.plus(subTotal);
      //   let finalResult = realNewPrice.plus(newSubtotal);
      //   setSubTotal(finalResult);
      //   setSubTotal(subTotal + currentPrice);
      //   let realNewPrice = new Big(newPrice);
      //   realNewPrice.plus(subTotal);
      setSubTotal(
        (Number.parseFloat(currentPrice) + Number.parseFloat(subTotal)).toFixed(
          2
        )
      );
      setCurrentPrice(0);
    }
  }, [currentPrice]);

  useEffect(() => {
    // console.log("Arayehaaaa:", priceArray);
  }, [priceArray]);

  //   useEffect(() => {
  //     if (teamCartItem) {
  //       setCounter(counter + 1);
  //       setSubTotal(0);
  //     }
  //   }, [teamCartItem]);

  const onClickCheckOut = async () => {
    // console.log(priceArray);
    // console.log(teamCartItem);
    localStorage.setItem("TestPriceArray", JSON.stringify(priceArray));
    localStorage.setItem("TestteamCartItem", JSON.stringify(teamCartItem));
    localStorage.setItem("courseName", JSON.stringify(courseNameArray));
    let finalItem = [];
    let finalUsers = [];
    const finalQuantity = 0;
    teamCartItem.map((items, key) => {
      finalItem.push({
        id: items.itemId,
        price: priceArray[key],
        name: courseNameArray[key],
        quantity: items.usersNotTookCourse.length,
      });
      finalUsers.push(items.usersNotTookCourse);
    });
    localStorage.setItem("finalItem", JSON.stringify(finalItem));
    // console.log("final item:", finalItem);
    // console.log("final users:", finalUsers);
    // console.log("final quantity:", finalQuantity);

    if (
      user &&
      finalItem.length > 0 &&
      finalUsers.length > 0 &&
      priceArray.length > 0
    ) {
      //go to stripe
      const stripe = await stripePK;
      const stripeSession = await axios.post("/create-checkout-session", {
        customerID: user.UserID,
        items: finalItem,
        email: user.email,
        // selfEmail: selfCheckOut,
        // companyTeams: teamCheckOut,
        // giftEmails: giftCheckOut,
        users: finalUsers,
        quantity: finalQuantity,
        teamId: props.currentTeamId,
      });
      // console.log("Items:", items);

      const result = await stripe.redirectToCheckout({
        sessionId: stripeSession.data.id,
      });
      if (result.error) {
        alert(result.error.message);
      } else {
        const db = firebaseApp.firestore();

        onAuthStateChanged(auth, (userm) => {
          if (userm) {
            db.collection("TeamsCart")
              .doc(teamCartItem[0].cartId)
              .delete()
              .then(() => {
                setSubTotal(0);
              })
              .then(() => {
                setCounter(counter + 1);
              });
          }
        });
      }
      // console.log("Items:", items);
    }
  };

  return (
    <Container>
      {subTotal > 0 ? (
        <CheckOutContainer>
          {/* onClick={() => onClickCheckOut()} */}
          <CheckOutButton>Check out with ${subTotal}</CheckOutButton>
        </CheckOutContainer>
      ) : (
        <div>The cart is empty.</div>
      )}
      <div>
        {teamCartItem ? (
          teamCartItem.map((item, key) => (
            // expanded={expanded === key} onChange={handleChange(key)}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading} component={"span"}>
                  <ShowCoursePrice
                    courseId={item.courseId}
                    quantityProp={item.usersNotTookCourse.length}
                    //   subTotalProp={subTotal}
                    changeSubTotalProp={setCurrentPrice}
                    key={key + counter}
                    index={key}
                    changePriceArray={setPriceArray}
                    companyPriceArray={priceArray}
                  />
                </Typography>

                <Typography
                  className={classes.secondaryHeading}
                  component={"span"}
                >
                  <SomeText>Item added by:</SomeText>
                  <ShowUserEmail userId={item.createdBy} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.detail} component={"span"}>
                  <ShowCourseName
                    courseId={item.courseId}
                    changeCourseNameArray={setCourseNameArray}
                    courseNameArrayValue={courseNameArray}
                    index={key}
                  />
                  <NumberOfUsersContainer>
                    <NumberOfUsersNotTookCourse>
                      {item.usersNotTookCourse.length} user(s) have not taken
                      the course.
                    </NumberOfUsersNotTookCourse>
                    <NumberOfUsersTookCourse>
                      {item.usersTookCourse.length} user(s) already took the
                      course.
                    </NumberOfUsersTookCourse>
                  </NumberOfUsersContainer>
                  <NoteContainer>
                    * You are charged for {item.usersNotTookCourse.length} users
                  </NoteContainer>
                  <DeleteItemContainer>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        onClickDeleteItem(item.cartId, item.itemId)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </DeleteItemContainer>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </Container>
  );
}

export default TeamsBasket;

const Container = styled.div`
  padding: 20px;
`;

const SomeText = styled.div`
  margin-right: 5px;
  color: black;
`;

const CheckOutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 10px 0px;
  margin-bottom: 10px;
`;
const SubTotal = styled.div``;
const CheckOutButton = styled.button`
  border: none;
  background-color: #5fb05f;
  padding: 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
`;

const NumberOfUsersNotTookCourse = styled.div``;
const NumberOfUsersTookCourse = styled.div``;
const NumberOfUsersContainer = styled.div`
  display: flex;
`;
const NoteContainer = styled.div`
  color: red;
  font-size: 10px;
`;

const DeleteItemContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
