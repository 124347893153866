import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowCourseStatus from "../Components/ShowCourseStatus";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import { useLocation } from "react-router-dom";
import safeTLogo from "../Images/full-logo.png";
import certificateBadge from "../Images/shield_cert.png";
import presidentSignature from "../Images/CS_signature.png";
import secLogo from "../Images/espec-logo-copy.png";
import certTop from "../Images/CertOfComp-OH.png";
import certBottom from "../Images/bottom_cert_main.png";
import axios from "axios";
import "../App.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridActionsCellItem,
  GridFilterAltIcon,
} from "@mui/x-data-grid";
// import axios from "axios";
// import { styled } from "@mui/material/styles";

const columns = [
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "name", label: "Course Name" }, //, minWidth: 100
  { id: "status", label: "Course Status" },
];

// const CustomToolBarContainer = styled(GridToolbarContainer)`
//   display: flex,
//   gap: 10px !important,
//   padding: 8px,
// `;

// const CustomGridToolbar = () => {
//   return (
//     <CustomToolBarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//       <GridToolbarExport />
//     </CustomToolBarContainer>
//   );
// };

// const dataGridColumns = [
//   { field: "userEmail", headerName: "User Email", width: 150, flex: 1 },
//   { field: "courseName", headerName: "Course Name", width: 150, flex: 1 },
//   { field: "courseStatus", headerName: "Course Status", width: 150, flex: 1 },
//   {
//     field: "certificate",
//     headerName: "Download Certificate",
//     width: 150,
//     flex: 1,
//     // renderCell: (props) => (
//     //   <strong>
//     //     <Button
//     //       variant="contained"
//     //       size="small"
//     //       style={{ marginLeft: 16 }}
//     //       tabIndex={props.hasFocus ? 0 : -1}
//     //     >
//     //       Download PDF
//     //     </Button>
//     //   </strong>
//     // ),
//     renderCell:printCertificate
//   },
// ];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CompanyMemeberCourseStatusTable(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [recordProps, setRecordProps] = useState(props.data);
  const [emailSearchDisplay, setEmailSearchDisplay] = useState([]);
  const [courseNameEntered, setCourseNameEntered] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [courseStatusSelected, setCourseStatusSelected] = useState("");

  // ========================================================

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [dataGridData, setDataGridData] = useState(null);
  const [finalDataGridData, setFinalDataGridData] = useState(null);

  const [dataGridPageSize, setDataGridPageSize] = useState(5);

  const location = useLocation();
  const [currentDate, setCurrentData] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [stateName, setStateName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [certId, setCertId] = useState("");
  const [className, setClassName] = useState("");
  const [completeStateName, setCompleteStateName] = useState("");

  const printCertificate = (props) => (
    <strong>
      <Button
        variant="contained"
        size="small"
        style={{ marginLeft: 16 }}
        tabIndex={props.hasFocus ? 0 : -1}>
        Download PDF
      </Button>
    </strong>
  );

  const downloadCertificate = React.useCallback(
    (row) => () => {
      setTimeout(async () => {
        let fullName = await axios.post("/getUserFullName", {
          userEmail: row.userEmail,
        });
        // .then((data) => {
        //   userFullName=data.data;
        // });
        // console.log("ROW:", row);
        setClassName(row.className);
        setCompleteStateName(row.stateNameABR);
        setCertId(row.certificateId);
        setCurrentData(row.completeDate);
        setExpirationDate(row.ExpireDate);
        setUserFullName(fullName.data);
        const state = row.courseName?.split(" ")[0];
        setStateName(row.courseName);
        if (state !== "Ohio") {
          const input = document.getElementById("certificate");
          // input.style.visibility = "visible";
          html2canvas(input, {
            logging: true,
            letterRendering: 1,
            useCORS: true,
            // onclone: function () {
            //   input.style.display = "block";
            // },
          }).then((canvas) => {
            // input.style.visibility = "hidden";
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgData = canvas.toDataURL("img/png");
            const pdf = new jsPDF("l", "mm", "a4");

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;

            pdf.margin = {
              horiz: 15,
              vert: 20,
            };
            pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
            pdf.save("MyCertificate.pdf");
          });
        } else {
          const input = document.getElementById("OHCertificate");
          html2canvas(input, {
            logging: true,
            letterRendering: 1,
            useCORS: true,
          }).then((canvas) => {
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgData = canvas.toDataURL("img/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;
            pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
            pdf.save("MyCertificate.pdf");
          });
        }
      });
    },
    []
  );

  // const downloadCertificate1=()=>{

  // }

  const dataGridColumns = [
    { field: "userEmail", headerName: "User Email", width: 150, flex: 1 },
    { field: "courseName", headerName: "Course Name", width: 150, flex: 1 },
    { field: "courseStatus", headerName: "Course Status", width: 150, flex: 1 },
    // {
    //   field: "certificate",
    //   headerName: "Download Certificate",
    //   width: 150,
    //   flex: 1,
    //   // renderCell: (props) => (
    //   //   <strong>
    //   //     <Button
    //   //       variant="contained"
    //   //       size="small"
    //   //       style={{ marginLeft: 16 }}
    //   //       tabIndex={props.hasFocus ? 0 : -1}
    //   //     >
    //   //       Download PDF
    //   //     </Button>
    //   //   </strong>
    //   // ),
    //   renderCell: printCertificate,
    // },
    {
      field: "actions",
      headerName: "Certificate",
      type: "actions",
      width: 150,
      getActions: (params) => [
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="Download"
        //   onClick={deleteUser(params.row.courseName)}
        // />,
        params.row.courseStatus == "Certified" ? (
          <strong>
            <Button
              variant="contained"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={props.hasFocus ? 0 : -1}
              onClick={downloadCertificate(params.row)}
              disabled={btnDisabled}
              // onClick={onClickPrintScreen(params.row.courseName)}
            >
              Download
            </Button>
          </strong>
        ) : (
          <div></div>
        ),
      ],
    },
  ];

  const onClickPrintScreen = (courseName) => {
    setStateName(courseName);
    const input = document.getElementById("certificate");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("l", "mm", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      pdf.margin = {
        horiz: 15,
        vert: 20,
      };

      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
      // pdf.addImage(
      //   imgData,
      //   "PNG",
      //   pdf.margin.horiz,
      //   pdf.margin.vert,
      //   canvasWidth,
      //   canvasHeight
      // );
      // pdf.addImage(imgData, "PNG", 0, 0, canvasWidth, canvasHeight);
      pdf.save("MyCertificate.pdf");
    });
  };

  const onClickPrintScreenOH = () => {
    const input = document.getElementById("OHCertificate");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.addImage(imgData, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      pdf.addImage(imgData, "PNG", 5, 5, pageWidth - 10, pageHeight - 10);
      pdf.save("MyCertificate.pdf");
    });
  };

  useEffect(() => {
    if (location.state?.courseId) {
      axios
        .post("/returnCourseName", { courseId: location.state.courseId })
        .then((data) => {
          setStateName(data.data);
        });
    }
  }, [location.state]);

  // const { data } = useDemoData({
  //   dataSet: "Commodity",
  //   rowLength: 4,
  //   maxColumns: 6,
  // });

  // useEffect(() => {
  //   // console.log("The built-in data:", data);
  // }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ==========================================================

  // const onChangeEmailSearch = (e) => {
  //   if (teamMembers) {
  //     let newArray = [...teamMembers];
  //     for (var i = 0; i < newArray.length; i++) {
  //       if (
  //         newArray[i].userEmail
  //           .toUpperCase()
  //           .indexOf(e.target.value.toUpperCase()) > -1
  //       ) {
  //         newArray[i].displayEmail = false;
  //       } else {
  //         newArray[i].displayEmail = true;
  //       }
  //     }
  //     setTeamMembers(newArray);
  //   }
  // };

  const onChangeCourseSearch = (e) => {
    if (teamMembers) {
      // console.log("Entering something:", e.target.value);
      setCourseNameEntered(e.target.value);

      // let newArray = [...recordProps];
      // for (var i = 0; i < newArray.length; i++) {
      //   if (
      //     newArray[i].courseName
      //       .toUpperCase()
      //       .indexOf(e.target.value.toUpperCase()) > -1
      //   ) {
      //     newArray[i].displayCourse = false;
      //   } else {
      //     newArray[i].displayCourse = true;
      //   }
      // }
      // setRecordProps(newArray);
    }
  };

  const onChangeStatusSearch = (e) => {
    if (teamMembers) {
      setCourseStatusSelected(e.target.value);
    }

    // const status = e.target.value;
  };

  // useEffect(() => {
  //   console.log("Dataaaa:", props.data);
  // }, [team]);

  useEffect(() => {
    console.log("data is changed:", props.data);
    if (props.data) {
      let teamID = "";
      let arr = props.data;
      let newArr = [];
      arr.forEach((items) => {
        teamID = items.teamId;
        items.sharedCourses.forEach((crsId) => {
          newArr.push({
            teamId: items.teamId,
            sharedCourse: crsId,
            userEmail: items.userEmail,
            role: items.role,
          });
        });
      });
      Promise.all(newArr).then(() => {
        setTeamMembers(newArr);
        axios
          .post("/returnUserRole", {
            userEmail: user.email,
            teamId: teamID,
          })
          .then((userRole) => {
            // console.log("User role is importsnt:", userRole);
            setCurrentUserRole(userRole.data.role);
            if (
              userRole.data.role === "admin" ||
              userRole.data.role === "owner"
            )
              setBtnDisabled(false);
          });
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (teamMembers) {
      let tempArr = [...teamMembers];
      // console.log("Team members:", recordProps);
      tempArr.forEach((item, key) => {
        item.id = key;
      });
      console.log("Temp array:", tempArr);
      setMainRecordProps(tempArr);
      setDataGridData(tempArr);
    }
    return () => {
      setMainRecordProps(null);
    };
  }, [teamMembers]);

  useEffect(() => {
    console.log("Say something!");
    return () => {
      setMainRecordProps(null);
      setTeamMembers(null);
    };
  }, []);

  useEffect(() => {
    // console.log("Record khodam:", mainRecordProps);
  }, [mainRecordProps]);

  const formatWithOrdinal = (day) => {
    let j = day % 10,
      k = day % 100;
    if (j == 1 && k != 11) return day + "st";
    if (j == 2 && k != 12) return day + "nd";
    if (j == 3 && k != 13) return day + "rd";
    return day + "th";
  };

  const formatDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let day = formatWithOrdinal(date.getDate());
    let monthName = months[date.getMonth()];
    let year = date.getFullYear();
    return `${monthName} ${day}, ${year}`;
  };

  const extractDataGridData = async () => {
    if (dataGridData && dataGridData?.length != 0) {
      let states = [
        ["Alabama", "AL"],
        ["Alaska", "AK"],
        ["American Samoa", "AS"],
        ["Arizona", "AZ"],
        ["Arkansas", "AR"],
        ["Armed Forces Americas", "AA"],
        ["Armed Forces Europe", "AE"],
        ["Armed Forces Pacific", "AP"],
        ["California", "CA"],
        ["Colorado", "CO"],
        ["Connecticut", "CT"],
        ["Delaware", "DE"],
        ["District of Columbia", "DC"],
        ["Florida", "FL"],
        ["Georgia", "GA"],
        ["Guam", "GU"],
        ["Hawaii", "HI"],
        ["Idaho", "ID"],
        ["Illinois", "IL"],
        ["Indiana", "IN"],
        ["Iowa", "IA"],
        ["Kansas", "KS"],
        ["Kentucky", "KY"],
        ["Louisiana", "LA"],
        ["Maine", "ME"],
        ["Marshall Islands", "MH"],
        ["Maryland", "MD"],
        ["Massachusetts", "MA"],
        ["Michigan", "MI"],
        ["Minnesota", "MN"],
        ["Mississippi", "MS"],
        ["Missouri", "MO"],
        ["Montana", "MT"],
        ["Nebraska", "NE"],
        ["Nevada", "NV"],
        ["New Hampshire", "NH"],
        ["New Jersey", "NJ"],
        ["New Mexico", "NM"],
        ["New York", "NY"],
        ["North Carolina", "NC"],
        ["North Dakota", "ND"],
        ["Northern Mariana Islands", "NP"],
        ["Ohio", "OH"],
        ["Oklahoma", "OK"],
        ["Oregon", "OR"],
        ["Pennsylvania", "PA"],
        ["Puerto Rico", "PR"],
        ["Rhode Island", "RI"],
        ["South Carolina", "SC"],
        ["South Dakota", "SD"],
        ["Tennessee", "TN"],
        ["Texas", "TX"],
        ["US Virgin Islands", "VI"],
        ["Utah", "UT"],
        ["Vermont", "VT"],
        ["Virginia", "VA"],
        ["Washington", "WA"],
        ["West Virginia", "WV"],
        ["Wisconsin", "WI"],
        ["Wyoming", "WY"],
      ];

      console.log("Salam Be Data:", dataGridData);
      let tempDataGridArr = [...dataGridData];
      let mainDataGridArr = [...dataGridData];
      for (var i = 0; i < tempDataGridArr?.length; i++) {
        const courseStat = await axios.post("/courseStatus", {
          courseId: tempDataGridArr[i].sharedCourse,
          userEmail: tempDataGridArr[i].userEmail,
        });
        const courseExpirationDate = await axios.post(
          "/returnMyCoursesInformation",
          { courseId: tempDataGridArr[i].sharedCourse }
        );

        let isCourseExpired;
        let mainDate;
        let expDate;
        let courseStatCertifiedAt = courseStat.data?.certifiedAt;
        let courseExpirationMonths = courseExpirationDate.data.expireMonth;

        let d = new Date(courseStatCertifiedAt);
        let certificateId = d.valueOf() - 45 * 31536000000;

        if (courseStatCertifiedAt) {
          let certificationDate = new Date(courseStatCertifiedAt);
          let expirationDate = new Date(certificationDate);
          expirationDate.setMonth(
            certificationDate.getMonth() + courseExpirationMonths
          );

          isCourseExpired = expirationDate < new Date() ? true : false;

          mainDate = formatDate(certificationDate);
          expDate = formatDate(expirationDate);
        }

        // const courseName = await axios.post("/returnCourseName", {
        //   courseId: tempDataGridArr[i].sharedCourse,
        // });

        // let date = new Date(courseStat.data?.certifiedAt);
        // console.log("Control Date:", date);
        // let datee = date;
        // let newDate = new Date(
        //   datee.setMonth(datee.getMonth() + courseExpirationDate.data.expireMonth)
        // );
        // console.log("New Date:", newDate);

        // let current = new Date();
        // console.log("Current Date:", current);
        // let isCourseExpired = newDate < current ? true : false;

        // // ###############Complete:Expired#####################
        // let mainDate;
        // let expDate;
        // if (date) {
        //   let months = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];
        //   let d = date;
        //   let day = d.getDay();
        //   let monthName = months[d.getMonth()];
        //   let year = d.getFullYear();
        //   let post =
        //     day + 1 == 1 || day + 1 == 21 || day + 1 == 31
        //       ? "st, "
        //       : day + 1 == 2 || day + 1 == 22 || day + 1 == 32
        //       ? "nd, "
        //       : "th, ";
        //   mainDate = monthName + " " + (day + 1) + post + year;
        //   expDate = monthName + " " + (day + 1) + post + (year + 1);

        //   // setCurrentData(mainDate);
        //   // setExpirationDate(expDate);
        // }

        // mainDataGridArr[i].courseStatus = courseStat.data?.certifiedAt;

        mainDataGridArr[i].courseStatus = courseStat.data
          ? courseStat.data.isCertified
            ? isCourseExpired
              ? "Expired"
              : "Certified"
            : Number(courseStat.data.progressing.toFixed(2)) + "%"
          : "Pending";
        mainDataGridArr[i].courseName = courseExpirationDate.data.name;

        mainDataGridArr[i].completeDate = mainDate;
        mainDataGridArr[i].ExpireDate = expDate;
        mainDataGridArr[i].certificateId = certificateId;

        // let stateNameTemp = courseExpirationDate.data.name;
        // let classNameOnlyArr = stateNameTemp.split(" ");
        // let completeStateNameOnly = stateNameTemp.split(" Petroleum")[0];
        // let stateNameAbbr = "";
        // let input = completeStateNameOnly.replace(/\w\S*/g, function (txt) {
        //   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        // });
        // for (var i = 0; i < states.length; i++) {
        //   if (states[i][0] == input) {
        //     stateNameAbbr = states[i][1];
        //   }
        // }
        // let classNameOnlyIndx = classNameOnlyArr.indexOf("Class") + 1;
        // let classNameOnly = classNameOnlyArr[classNameOnlyIndx];

        let stateNameTemp = courseExpirationDate.data.name;

        // Extracting the complete state name before "Petroleum"
        let completeStateNameOnly = stateNameTemp?.split(" Petroleum")[0];

        // Formatting state name to match the format in your 'states' array
        let formattedStateName = completeStateNameOnly
          ?.split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");

        // Finding the abbreviation
        let stateNameAbbr =
          states.find((state) => state[0] === formattedStateName)?.[1] || "";

        let classNameOnlyArr = stateNameTemp?.split(" ");
        let classNameOnlyIndx = classNameOnlyArr?.indexOf("Class") + 1;

        // Check if the index is within the bounds of the array
        let classNameOnly =
          classNameOnlyIndx >= 0 && classNameOnlyIndx < classNameOnlyArr?.length
            ? classNameOnlyArr[classNameOnlyIndx]
            : "";

        mainDataGridArr[i].className = classNameOnly;
        mainDataGridArr[i].stateNameABR = stateNameAbbr;

        // mainDataGridArr[i].courseInfo = courseStat.data;

        // console.log("Every time the data add:", mainDataGridArr);
      }

      console.log("Every time the data add:", mainDataGridArr);
      setFinalDataGridData(mainDataGridArr);
    }
  };

  useEffect(() => {
    console.log("Data Grid Data:", dataGridData);
    if (dataGridData) {
      extractDataGridData();
    }
  }, [dataGridData]);

  useEffect(() => {
    if (dataGridData) {
      console.log("Final data:", finalDataGridData);
    }
  }, [finalDataGridData]);

  return (
    <Container>
      <CertificateOuterContainer>
        {stateName != "" && userFullName != "" ? (
          stateName?.split(" ")[0] !== "Ohio" ? (
            <OtherCertificateContainer>
              <OtherCertificate>
                <CertificateContainer id="certificate">
                  <CertificateTop>
                    <CertifiedLogoContainer>
                      <CertifiedLogo src={certificateBadge} />
                    </CertifiedLogoContainer>
                    <Rectangle></Rectangle>
                    <ArrowDown></ArrowDown>
                    <ArrowDownSec></ArrowDownSec>
                    <ArrowDownThird></ArrowDownThird>
                  </CertificateTop>

                  <Content>
                    <UserNameContainer>{userFullName}</UserNameContainer>

                    <CertificateIDContainer>
                      Certificate ID: {completeStateName}
                      {"-"}
                      {className}
                      {"-"}
                      {certId}
                    </CertificateIDContainer>

                    <Line1>
                      eSpec Software affirms that the name above has completed
                      the
                    </Line1>
                    <Line2>
                      examination in accordance with state regulations and
                      standards for
                    </Line2>
                    <CourseNameContainer>
                      {/* <ShowCourseName
                    courseId={location.state.courseId}
                    changeState={setStateName}
                  /> */}
                      {stateName}
                      {/* <CourseNameContainer></CourseNameContainer> */}
                    </CourseNameContainer>
                    <CerificateID></CerificateID>
                    <CertificateExpiration>
                      <Current>Completed: {currentDate}</Current>
                      <Expiration>Expires: {expirationDate}</Expiration>
                    </CertificateExpiration>
                    <InnerContent>
                      <CertificateSignature>
                        <Header>
                          <Logo src={safeTLogo} />
                        </Header>
                        <Signature>
                          <SignatureContainer>
                            <ManagerSignature src={presidentSignature} />
                          </SignatureContainer>
                          <PresidentName>Casey Smith, President</PresidentName>
                        </Signature>

                        <CompanyLogo>
                          <SecLogo src={secLogo} />
                        </CompanyLogo>
                      </CertificateSignature>
                    </InnerContent>
                  </Content>
                  <Footer>
                    eSpec Software LLC. Phone:(800)274-4437 Address: 12815
                    Premier Center Court Plymouth, MI 48170 Website:
                    https://safet1stonline.com{" "}
                  </Footer>
                </CertificateContainer>
              </OtherCertificate>
              <PrintScreenContainer>
                <PrintScreenButton onClick={onClickPrintScreen}>
                  Download Certificate
                </PrintScreenButton>
              </PrintScreenContainer>
            </OtherCertificateContainer>
          ) : (
            <OhioCertificateContainer>
              <OHCertificate>
                <OHCertificateContainer id="OHCertificate">
                  <CertificateTopOH className="certificateTop">
                    {/* <LineTopOh className="certificateTop char3"> */}
                    {/* <svg viewBox="-190 30 700 150">
                <path
                  id="curve"
                  d="M73.2,148.6c4-6.1,65.5-96.8,178.6-5.6c111.3,1.2,170.8,90.3,175.1,97"
                />
                <text width="500">
                  <textPath href="#curve">Cerificate</textPath>
                </text>
              </svg> */}
                    {/* </LineTopOh> */}
                    {/* <LineTopOh
                className="certificateTop"
                style={{ position: "absolute", top: "230px" }}
              >
                of
              </LineTopOh>
              <LineTopOh className="certificateTop">Completion</LineTopOh> */}
                    <CertificateTopFont src={certTop}></CertificateTopFont>
                  </CertificateTopOH>

                  <Content>
                    <Line1OH>eSpec Software</Line1OH>
                    <Line2OH>A Certified Course Sponsor for</Line2OH>
                    <Line3OH>
                      "Bureau of Underground Storage Tank Regulations"
                    </Line3OH>

                    <CourseNameContainerOH>
                      {/* <ShowCourseName
                    courseId={location.state.courseId}
                    changeState={setStateName}
                  /> */}
                      {stateName?.split(" ")[2] +
                        " " +
                        stateName?.split(" ")[3] +
                        " " +
                        stateName?.split(" ")[4] +
                        " Training"}
                      {/* <CourseNameContainer></CourseNameContainer> */}
                    </CourseNameContainerOH>
                    <Line4>Recongnizing</Line4>
                    <UserNameContainer>{userFullName}</UserNameContainer>
                    <CerificateID></CerificateID>
                    <Line3>as having successfully completed</Line3>
                    <CourseNameContainerOH>{stateName}</CourseNameContainerOH>
                    <Line3>Presented</Line3>

                    <InnerContent>
                      <CertificateSignature>
                        {/* <Header>
                    <Logo src={safeTLogo} />
                  </Header> */}
                        <SignatureOhio>
                          <CertificateBottomImageContainer>
                            <CertificateBottomImage src={certBottom} />
                            <SignatureContent>
                              <CertificateExpiration>
                                <Current> {currentDate}</Current>
                              </CertificateExpiration>
                              <SignatureContainer>
                                <ManagerSignature src={presidentSignature} />
                              </SignatureContainer>
                              <PresidentName>
                                Casey Smith, President
                              </PresidentName>
                            </SignatureContent>
                          </CertificateBottomImageContainer>
                          {/* <SignatureContent>
                      <SignatureContainer>
                        <ManagerSignature src={presidentSignature} />
                      </SignatureContainer>
                      <PresidentName>Casey Smith, President</PresidentName>
                    </SignatureContent> */}
                        </SignatureOhio>

                        {/* <CompanyLogo>
                    <SecLogo src={secLogo} />
                  </CompanyLogo> */}
                      </CertificateSignature>
                    </InnerContent>
                  </Content>
                  <Footer>
                    eSpec Software LLC. Phone:(800)274-4437 Address: 12815
                    Premier Center Court Plymouth, MI 48170 Website:
                    https://safet1stonline.com{" "}
                  </Footer>
                </OHCertificateContainer>
              </OHCertificate>
              <PrintScreenContainer>
                <PrintScreenButton onClick={onClickPrintScreenOH}>
                  Download Certificate
                </PrintScreenButton>
              </PrintScreenContainer>
            </OhioCertificateContainer>
          )
        ) : (
          <div>Loading...</div>
        )}
      </CertificateOuterContainer>

      <CustomTable>
        {/* <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
          <CourseSearchContainer>
            <CourseSearch
              onChange={(e) => onChangeCourseSearch(e)}
              placeholder="Course..."
            />
            <CourseSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </CourseSearchButton>
          </CourseSearchContainer>
          <StatusSearchContainer>
            <StatusSearch
              defaultValue="select"
              onChange={(e) => onChangeStatusSearch(e)}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="firstHalf"> {`<=`}50%</option>
              <option value="secondHalf">{`>`}50%</option>
              <option value="certified">Certified</option>
              <option value="expired">Expired</option>
            </StatusSearch>
            <StatusSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </StatusSearchButton>
          </StatusSearchContainer>
        </SearchTable> */}
        {/* ===============main pagination table is here============== */}
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              options={{
                exportButton: true,
                exportAllData: true,
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {mainRecordProps ? (
                  mainRecordProps
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            display:
                              !row?.displayEmail &&
                              !row?.displayCourse &&
                              !row?.displayStatus
                                ? ""
                                : "none",
                          }}
                        >
                          <TableCell>{row.userEmail}</TableCell>
                          <TableCell>
                            <ShowCourseName
                              courseId={row.sharedCourse}
                              keyProp={row.id}
                              courseName={courseNameEntered}
                              changeData={(val) => setTeamMembers(val)}
                              allData={teamMembers}
                            />
                          </TableCell>
                          <TableCell>
                            <ShowCourseStatus
                              courseId={row.sharedCourse}
                              userEmail={row.userEmail}
                              keyProp={row.id}
                              courseStatus={courseStatusSelected}
                              changeData={(val) => setTeamMembers(val)}
                              allData={teamMembers}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={
              mainRecordProps ? mainRecordProps?.length : teamMembers?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper> */}
        {/* ========================================================== */}
        {/* dataGridColumns */}
        {dataGridColumns &&
        finalDataGridData &&
        dataGridColumns?.length != 0 &&
        finalDataGridData?.length != 0 ? (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              columns={dataGridColumns}
              rows={finalDataGridData}
              components={{
                // Toolbar: CustomToolbar,
                // Toolbar: CustomGridToolbar,
                Toolbar: GridToolbar,
              }}
              style={{ backgroundColor: "white" }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={dataGridPageSize}
              onPageSizeChange={(newPageSize) =>
                setDataGridPageSize(newPageSize)
              }
            />
          </div>
        ) : (
          <div></div>
        )}

        {/* <Header>
          <FirstHeader>Student Email</FirstHeader>
          <SecondHeader>Course Name</SecondHeader>
          <ThirdHeader>Course Progress</ThirdHeader>
        </Header>
        <TableBody>
          {teamMembers ? (
            teamMembers.map((item, key) => (
              <TableRow
                style={{
                  display:
                    !item?.displayEmail &&
                    !item?.displayCourse &&
                    !item?.displayStatus
                      ? ""
                      : "none",
                }}
              >
                <FirstColumn>{item.userEmail}</FirstColumn>
                <SecondColumn>
                  <ShowCourseName
                    courseId={item.sharedCourse}
                    keyProp={key}
                    courseName={courseNameEntered}
                    changeData={(val) => setTeamMembers(val)}
                    allData={teamMembers}
                  />
                 
                </SecondColumn>
                <ThirdColumn>
                  <ShowCourseStatus
                    courseId={item.sharedCourse}
                    userEmail={item.userEmail}
                    keyProp={key}
                    courseStatus={courseStatusSelected}
                    changeData={(val) => setTeamMembers(val)}
                    allData={teamMembers}
                  />
                </ThirdColumn>
              </TableRow>
            ))
          ) : (
            <div></div>
          )}
        </TableBody> */}
      </CustomTable>
    </Container>
  );
}

export default CompanyMemeberCourseStatusTable;

const Container = styled.div`
  padding: 20px;
`;
const CustomTable = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
// const Header = styled.div`
//   border: 1px solid rgba(0, 0, 0, 0.2);
//   display: flex;
//   justify-content: space-around;
// `;
// const TableBody = styled.div`
//   overflow: auto;
// `;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
// const TableRow = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;
padding:5px;
`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 28px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);

// ##################For certificate######################
const CertificateOuterContainer = styled.div`
  /* visibility: hidden;
  width: 0;
  height: 0; */
  /* display: none; */
  opacity: 0;
  width: 0;
  height: 0;
`;
const CertificateContainer = styled.div`
  max-width: 900px;
  max-height: 600px;
  min-width: 700px;
  width: 100%;
  border: 10px solid #189ab4;
  /* padding: 20px; */
  background-image: repeating-linear-gradient(
    11deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 1px,
    transparent 0px,
    transparent 4px
  );
  background-size: 8px;
`;

const OHCertificateContainer = styled.div`
  max-width: 900px;
  /* max-height: 600px; */
  min-width: 700px;
  width: 100%;
  border: 10px solid #189ab4;
  /* padding: 20px; */
  background-image: repeating-linear-gradient(
    11deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 1px,
    transparent 0px,
    transparent 4px
  );
  background-size: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 30px 0;
`;
const Content = styled.div``;

const InnerContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 30px 0;
  font-size: 11px;
`;
const Logo = styled.img`
  width: 150px;
`;

const UserNameContainer = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
`;
const LineTopOh = styled.div``;
const Line1 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;
const Line2 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;

const Line3 = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 25px; */
  /* font-weight: 800; */
`;

const Line1OH = styled.div`
  /* margin-top: 20px; */
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;
const Line2OH = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;

const Line3OH = styled.div`
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 800;
`;

const Line4 = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 20px 0 0 0;
  /* font-weight: 800; */
  font-weight: 800;
`;

const CourseNameContainerOH = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin: 5px 0 5px 0;
  /* font-weight: 800; */
  font-weight: 800;
`;

const CerificateID = styled.div``;
const CertificateExpiration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
const CertificateSignature = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 10px 0 10px 0;
  width: 100%;
`;
const ManagerSignature = styled.img`
  width: 150px;
`;
const CertifiedLogo = styled.img`
  /* position: absolute;
  left: 0;
  top: 0; */

  /* height: 100%;
  width: 100%; */
  /* background-image: url(${certificateBadge});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
  /* -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1); */
  height: 150px;
  /* opacity: 0.2; */
  /* position: absolute; */
  /* background-image: url(certificateBadge); */
`;
const Current = styled.div`
  padding: 0 5px 0 5px;
`;
const Expiration = styled.div`
  border-left: 2px solid black;
  padding: 0 5px 0 5px;
`;

const PresidentName = styled.div`
  /* position: absolute; */
  border-top: 2px solid black;
  padding: 10px 0 10px 0;
  font-weight: 600;
`;

const SignatureContainer = styled.div`
  /* position: absolute; */
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Signature = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px 0 50px;
  /* position: relative;
  width: 100%; */
  /* background: url(${certBottom}); */
`;

const SignatureOhio = styled.div`
  /* display: flex;
  flex-direction: column;
  margin: 0 50px 0 50px; */
  position: relative;
  width: 100%;
  /* background: url(${certBottom}); */
`;

const CompanyLogo = styled.div``;
const SecLogo = styled.img`
  width: 150px;
`;
const CertifiedLogoContainer = styled.div`
  /* margin: 40px; */
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
`;

const CertificateTop = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  height: 150px;
`;

const CertificateTopOH = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 300px; */
  font-size: 50px;
  font-weight: 600;
  margin: 10px 100px 10px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
  /* font-family: "MedievalSharp", cursive; */
`;

const ArrowDown = styled.div`
  /* width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #f00; */
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -35px;
  z-index: 10;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #189ab4;
    transform: skew(0deg, 6deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #189ab4;
    transform: skew(0deg, -6deg);
  }
`;

const ArrowDownSec = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -95px;
  z-index: 1;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #5fb05f;
    transform: skew(0deg, 4deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #5fb05f;
    transform: skew(0deg, -4deg);
  }
`;

const ArrowDownThird = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  /* padding: 8px; */
  margin-bottom: 6px;
  height: 60px;
  top: -158px;
  z-index: 0;
  box-sizing: border-box;
  /* width: 200px; */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: rgba(1, 1, 1, 1);
    opacity: 0.2;
    transform: skew(0deg, 3deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: rgba(1, 1, 1, 1);
    opacity: 0.2;
    transform: skew(0deg, -3deg);
  }
`;

const Rectangle = styled.div`
  height: 50px;
  width: 100%;
  background: #189ab4;
`;

// const CourseNameContainer=styled.div``

const CertificateTopFont = styled.img`
  width: 100%;
`;

const CertificateBottomImage = styled.img`
  width: 100%;
`;
const CertificateBottomImageContainer = styled.div`
  /* position: absolute; */
  position: relative;
  /* width: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
`;

const SignatureContent = styled.div`
  /* display: flex; */
  flex-direction: column;
  /* margin: 0 50px 0 50px; */
  /* position: absolute; */

  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrintScreenContainer = styled.div`
  margin-top: 20px;
`;
const PrintScreenButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  background-color: rgba(95, 176, 95, 0.9);
  transition: all 0.2s ease-in-out;
  color: white;
  border-radius: 4px;
  border: none;
  height: 40px;

  &:hover {
    background-color: rgba(95, 176, 95, 1);
  }

  &:disabled {
    background-color: rgba(1, 1, 1, 0.1);
    cursor: default;
  }

  span {
    height: 20px !important;
    width: 20px !important;
  }
`;
const OHCertificate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OtherCertificate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OtherCertificateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OhioCertificateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CertificateIDContainer = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 200;
`;
