import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectCount, selectTotal } from "../features/counter/counterSlice";
import CartItem from "../Components/CartItem";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
const stripePromiseTestMode = loadStripe(
  "pk_test_51HC8ruLYpiPz9FF416QMwOiY1LJDy6oF3JqZ5YuATtIreOoh6ppEnQk5FQlhFzcpWumBZTefBvznFz595REDm48g00uktcs3u7"
);

const stripePromiseLiveMode = loadStripe(
  "pk_live_51HC8ruLYpiPz9FF4gF6Suk6enWcV7kocqHJdU1jMWm1sck1SlJCrsUftYPDDwWoZ8L6Txo9olw0l6Yv5NAZs2eDJ00KJiF3o00"
);

const stripePK = stripePromiseLiveMode;

function Basket() {
  const history = useNavigate();
  const ref = useRef(null);

  const items = useSelector(selectCount);
  const total = useSelector(selectTotal);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [teamId, setTeamId] = useState(
    JSON.parse(localStorage.getItem("teamId"))
  );
  const [teamUsers, setTeamUsers] = useState(
    JSON.parse(localStorage.getItem("teamUsers"))
  );

  const [userTeam, setUserTeam] = useState(null);
  const [totalPrice, setTotalPrice] = useState(total);

  const [teamMemberEmail, setTeamMemberEmail] = useState("");
  const [giftPersonEmail, setGiftPersonEmail] = useState("");
  const [selfEmailChecked, setSelfEmailChecked] = useState(true);

  const [selectedCheckoutUsers, setSelectedCheckoutUsers] = useState([]);

  const [selfCheckOut, setSelfCheckOut] = useState([]);
  const [teamCheckOut, setTeamCheckOut] = useState([]);
  const [teamNameCheckout, setTeamNameCheckout] = useState([]);
  const [giftCheckOut, setGiftCheckOut] = useState([]);

  const [clickedOutside, setClickedOutside] = useState(true);
  const [itemsQuantity, setItemsQuantity] = useState(1);

  const [displayTeamSubMenu, setDisplayTeamSubMenu] = useState("none");

  useEffect(() => {
    setTotalPrice(total);
  }, [total]);

  useEffect(() => {
    // console.log("we have some id here:", teamId);
  }, [teamId]);

  useEffect(() => {
    if (teamUsers && selectedCheckoutUsers.length > 0) {
      localStorage.removeItem("teamUsers");
      setTeamUsers(null);
      let tempArr = [...selectedCheckoutUsers];
      let secTempArr = [...teamUsers];

      let newArr = [...tempArr, ...secTempArr];
      // console.log("And we have some email there:", newArr);
      // let newArr = [...selectedCheckoutUsers, ...teamUsers];
      setSelectedCheckoutUsers(newArr);
      setItemsQuantity(itemsQuantity + secTempArr.length);
      // setSelectedCheckoutUsers([...selectedCheckoutUsers, ...teamUsers]);
    }
  }, [teamUsers, selectedCheckoutUsers]);

  useEffect(() => {
    // console.log("Itema", items);
    if (user) {
      axios.post("/returnTeams", { email: user.email }).then((data) => {
        setUserTeam(data.data);
      });

      setSelectedCheckoutUsers([
        ...selectedCheckoutUsers,
        {
          userEmail: user.email,
          type: "Self",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    // console.log("Team new members:", teamCheckOut);
  }, [teamCheckOut]);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onClickRemoveTag = (type, index) => {
    if (type === "Self") setSelfEmailChecked(false);
    let newArray = [...selectedCheckoutUsers];
    newArray.splice(index, 1);
    setSelectedCheckoutUsers(newArray);
    setItemsQuantity(itemsQuantity - 1);
  };

  const onClickAddTeamMember = () => {
    let newVariable = teamMemberEmail;
    if (!isEmpty(newVariable) && isEmail(newVariable)) {
      setSelectedCheckoutUsers([
        ...selectedCheckoutUsers,
        {
          userEmail: newVariable,
          type: "Team",
        },
      ]);
      setItemsQuantity(itemsQuantity + 1);
      setTeamMemberEmail("");
    }
  };

  const onClickAddGiftEmail = () => {
    let newVariable = giftPersonEmail;
    if (!isEmpty(newVariable) && isEmail(newVariable)) {
      setSelectedCheckoutUsers([
        ...selectedCheckoutUsers,
        {
          userEmail: newVariable,
          type: "Gift",
        },
      ]);
      setItemsQuantity(itemsQuantity + 1);
      setGiftPersonEmail("");
    }
  };

  const onChangeTeamInput = (e) => {
    setTeamMemberEmail(e.target.value);
  };

  const onChangeGiftInput = (e) => {
    setGiftPersonEmail(e.target.value);
  };

  const onClickCheckout = async () => {
    // console.log("Items:", items);
    // console.log("users:", selectedCheckoutUsers);
    // console.log("quantity:", itemsQuantity);
    if (user && selectedCheckoutUsers.length > 0) {
      //go to stripe
      const stripe = await stripePK;
      console.log("Itemha:", items);
      const stripeSession = await axios.post("/create-checkout-session", {
        customerID: user.UserID,
        items: items,
        email: user.email,
        // selfEmail: selfCheckOut,
        // companyTeams: teamCheckOut,
        // giftEmails: giftCheckOut,
        users: selectedCheckoutUsers,
        quantity: itemsQuantity,
        teamId: "self",
      });
      // console.log("Items:", items);

      const result = await stripe.redirectToCheckout({
        sessionId: stripeSession.data.id,
      });
      if (result.error) {
        alert(result.error.message);
      }
      // console.log("Items:", items);
    } else {
      // history("/login" );
    }
  };

  const onChangeSelfCheckBox = (event) => {
    if (user && event.target.checked) {
      setSelfEmailChecked(true);
      setSelectedCheckoutUsers([
        ...selectedCheckoutUsers,
        {
          userEmail: user.email,
          type: "Self",
        },
      ]);
      setSelfCheckOut(user.email);
      setItemsQuantity(itemsQuantity + 1);
    } else {
      setSelfEmailChecked(false);
      let newArray = [...selectedCheckoutUsers];

      let userIndx = -1;
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i].userEmail === user.email && newArray[i].type == "Self")
          userIndx = i;
      }
      if (userIndx > -1) {
        newArray.splice(userIndx, 1);
        setSelectedCheckoutUsers(newArray);
      }
      setSelfCheckOut(null);
      setItemsQuantity(itemsQuantity - 1);
    }
  };

  const onChangeTeamSelect = (e, teamID, teamName) => {
    if (e.target.checked) {
      axios.post("/returnTeamMembers", { teamId: teamID }).then((data) => {
        let newArray = [...selectedCheckoutUsers];
        data.data.forEach((item) => {
          newArray.push({
            userEmail: item.useEmail,
            teamId: teamID,
            type: "Team",
          });
        });
        setSelectedCheckoutUsers(newArray);
        setItemsQuantity(newArray.length);
        setTeamCheckOut([...teamCheckOut, teamID]);
        setTeamNameCheckout([...teamNameCheckout, teamName]);
      });
    } else {
      let newArray = [...teamCheckOut];
      let newNameArray = [...teamNameCheckout];
      let indexNum = teamCheckOut.indexOf(teamID);
      if (indexNum > -1) {
        newArray.splice(indexNum, 1);
        newNameArray.splice(indexNum, 1);
        setTeamNameCheckout(newNameArray);
        setTeamCheckOut(newArray);
        setItemsQuantity(itemsQuantity - 1);
      }

      let allUsers = [...selectedCheckoutUsers];
      for (var i = 0; i < allUsers.length; i++) {
        if (allUsers[i].teamId === teamID) {
          allUsers.splice(i, 1);
          i--;
        }
      }
      setSelectedCheckoutUsers(allUsers);
    }
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    // console.log("Changing:", selectedCheckoutUsers);
  }, [selectedCheckoutUsers]);

  useEffect(() => {
    // console.log("Changing quantity:", itemsQuantity);
    // if (itemsQuantity !== 0) {
    let newTotal = total;
    // console.log("new quantity:", itemsQuantity);
    // console.log("new total:", Number.parseFloat(newTotal));
    newTotal = Number.parseFloat(newTotal) * Number.parseFloat(itemsQuantity);
    newTotal = newTotal.toFixed(2);
    setTotalPrice(newTotal);
    // }
  }, [itemsQuantity]);

  return (
    <Container>
      {/* Left */}
      <BasketItemsContainer>
        {items.length === 0
          ? "There is no item in the cart."
          : items.map((item) => (
              <Item>
                <CartItem
                  // id={item.props.id}
                  // title={item.props.title}
                  // description={item.props.description}
                  // price={item.props.price}
                  // quantity={itemsQuantity}
                  id={item.id}
                  title={item.name}
                  description={item.description}
                  price={item.price}
                  quantity={itemsQuantity}
                />
              </Item>
            ))}
      </BasketItemsContainer>

      {/* Right */}
      <PaymentDetailsContainer>
        {items.length === 0 ? (
          <div />
        ) : (
          <RightSideMenu>
            <PaymentDetails>
              <SubTotal>
                SubTotal (
                {items.length == 1
                  ? `${items.length} item`
                  : `${items.length} items`}
                ):
              </SubTotal>
              <PriceTotal>${totalPrice}</PriceTotal>
              <IndividualCheckOut onClick={() => onClickCheckout()} role="link">
                Check Out
              </IndividualCheckOut>
            </PaymentDetails>
            {/* {user?.email ? (
              <StudentSelection>
                <IndividualSelect>
                  <MeCheckBox
                    type="checkbox"
                    onChange={(e) => onChangeSelfCheckBox(e)}
                    checked={selfEmailChecked}
                  />{" "}
                  {user.email}
                </IndividualSelect>
               
                <StudentSelect>
                  <GiftTitle>Gift to:</GiftTitle>
                  <StudentSelectionGift>
                    <IndividualInput
                      placeholder="Email..."
                      onChange={(e) => onChangeGiftInput(e)}
                      value={giftPersonEmail}
                    ></IndividualInput>
                    <AddIndividualButton onClick={() => onClickAddGiftEmail()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-plus-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                    </AddIndividualButton>
                  </StudentSelectionGift>
                </StudentSelect>
                <EmailTagsContainer
                  style={{
                    display: selectedCheckoutUsers.length !== 0 ? "" : "none",
                  }}
                >
                  <EmailTags>
                    {selectedCheckoutUsers ? (
                      selectedCheckoutUsers.map((item, i) => (
                        <EmailTagContainer>
                          <RemoveTagButton
                            onClick={() => onClickRemoveTag(item.type, i)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-x-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          </RemoveTagButton>
                          <EmailTag value={item.userEmail} />
                        </EmailTagContainer>
                        // <EmailTag>{item.userEmail}</EmailTag>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </EmailTags>
                </EmailTagsContainer>
              </StudentSelection>
            ) : (
              <div></div>
            )} */}
          </RightSideMenu>
        )}
      </PaymentDetailsContainer>
    </Container>
  );
}

export default Basket;

const Container = styled.div`
  /* position: relative; */
  /* width: 100%; */
  /* padding: 40px; */
  /* height: 100%; */
  padding: 70px 0px 20px 0px;
  display: grid;
  grid-template-columns: auto 300px;
  grid-gap: 10px;
  /* background: brown; */
`;

const BasketItemsContainer = styled.div`
  /* width: 100%; */
  /* height: 100%; */
  /* background: yellow; */
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2rem;
  padding: 2rem;
`;
const PaymentDetailsContainer = styled.div`
  /* position: relative; */
  /* padding: 2rem; */
  padding-top: 2rem;
  padding-right: 2rem;
`;

const Item = styled.div``;
const PaymentDetails = styled.div`
  /* position: absolute;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  height: 210px; */
  /* margin: 10px; */
  /* margin-top: 2rem;
  width: 16rem;
  padding: 20px; */

  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
  /* height: 145px; */
  padding: 20px;
`;

const SubTotal = styled.div`
  font-size: 1rem;
`;
const PriceTotal = styled.div`
  padding: 10px;
  font-weight: 700;
  font-size: 2rem;
`;
const IndividualCheckOut = styled.button`
  padding: 15px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #f26669;
  color: white;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #f15b5d;
  }
`;

const RightSideMenu = styled.div``;
const StudentSelection = styled.div`
  margin-top: 15px;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
  /* height: 145px; */
  padding: 20px;
`;

const StudentSelect = styled.div``;
const TeamSelect = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;
const IndividualSelect = styled.div``;

const MeCheckBox = styled.input``;

const Selection = styled.div`
  position: relative;
`;
const SelectionBox = styled.div`
  display: flex;
`;
const SelectInputText = styled.input`
  border-radius: 4px 0px 0px 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-right: none;
  outline: none;
  width: 100%;
`;
const SelectButton = styled.button`
  cursor: pointer;
  outline: none;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;
const SelectText = styled.div``;

const SelectItems = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

const SelectInputCheckBox = styled.input`
  margin-top: 2px;
  margin-right: 5px;
`;

const SelectionMenu = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 5px;
  overflow: auto;
  height: 93px;
  position: absolute;
  width: 100%;
  background-color: white;
  transition: all 0.2s;
  z-index: 10;
`;

const IndividualInput = styled.input`
  border-radius: 4px 0px 0px 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-right: none;
  outline: none;
  width: 100%;
`;
const AddIndividualButton = styled.button`
  cursor: pointer;
  outline: none;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const TeamTitle = styled.div``;
const GiftTitle = styled.div``;

const StudentSelectionGift = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
`;

const EmailTags = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
`;
const EmailTag = styled.input`
  /* width: 50px; */
  padding: 5px;
  width: 94%;
  font-size: 11px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-right: 25px;
`;
const EmailTagsContainer = styled.div`
  position: relative;
`;

const EmailTagContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const RemoveTagButton = styled.div`
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 22px;

  position: absolute;
  right: 1px;
  /* padding-top: 3px;
  padding-left: 3px; */
  svg {
    fill: #f15b5d;
  }
`;

const TeamInput = styled.input`
  border-radius: 4px 0px 0px 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-right: none;
  outline: none;
  width: 100%;
`;
const AddTeamButton = styled.button`
  cursor: pointer;
  outline: none;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const TeamStudentSelection = styled.div`
  display: flex;
`;
